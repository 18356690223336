<template>
  <svg
      class="img-fluid"
      :class="hidden ? 'hidden' : ''"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72.3 72.3"
      :width="width"
      :height="height"
  >
    <defs>
      <clipPath id="clippath">
        <circle cx="36.15" cy="36.15" r="36.15" fill="none" stroke-width="0"/>
      </clipPath>
      <clipPath id="clippath-1">
        <circle cx="35.91" cy="36.24" r="36.24" fill="none" stroke-width="0"/>
      </clipPath>
    </defs>
    <g id="Headers">
      <g clip-path="url(#clippath)">
        <g clip-path="url(#clippath-1)">
          <circle cx="35.91" cy="36.24" r="36.24" fill="#e7f3fc" stroke-width="0"/>
        </g>
        <g class="person" v-if="index === 1">
          <path d="M39.26,42.97s13.19,1.05,17.65,9.98c4.47,8.93,2.98,25.73,2.98,25.73l-57.21.85S-1,52.52,22.25,42.97c0,0,10.34-3.2,11.34-2.99,1,.21,5.68,2.99,5.68,2.99Z" fill="#5ba97d" stroke-width="0"/>
          <path d="M39.31,38.28s7.34-3.19,9.89,0c2.55,3.19,9.84,10.21-13.9,16.27l-3.08.8s-13.01-3.81-13.16-9.24c-.16-5.43,8-6.85,13.88-6.23,5.87.62,6.38-1.6,6.38-1.6Z" fill="#5ba97d" stroke-width="0"/>
          <path d="M28.31,39.71v9.2l4.31,5s7.98-3.88,9.25-8.08l.43-10.63-13.98,4.52Z" fill="#eec3ae" stroke-width="0"/>
          <g>
            <path d="M42.79,16.15c.09-.16.16-.3.2-.37.32-.64.5-1.48.4-2.2-.08-.65-.37-1.33-.78-1.91-.31-1.13-1.13-2.21-2.5-2.86-2-.94-3.95-.43-5.06.77-.75.09-1.48.42-2.04,1.07-.72.83-1.04,1.83-.95,2.82-.56.21-1.11.47-1.66.77-2.37,1.32-3.73,3.76-4.69,6.26-.29.66-.52,1.35-.7,2.05-.59,2.33,3.33,3.53,4.1,1.22.17-.5.35-1.06.56-1.63,1.07-2.27,3.42-3.37,5.9-3.48,1.48,1.98,3.49,3.42,6.03,3.9,1.67.32,1.6.63,1.84,2.51.2,1.53-.07,2.87-.3,4.35-.32,2.03,2.5,3.46,3.8,1.71,3.62-4.87.66-11.98-4.15-14.98Z" fill="#edb34e" stroke-width="0"/>
            <path d="M46.03,29.24c-.52.15-.81.47-1.08.91-.1.16-.19.34-.27.51-.1-.24-.19-.48-.24-.73-.12-.6-.16-1.2-.14-1.81.03-1.28.1-2.62,0-3.89-.09-1.13-.22-2.38-.82-3.38-.59-.99-1.54-1.86-2.53-2.43-2.03-1.15-4.53-1.13-6.79-.96-1.02.07-2,.29-2.92.64-1.57.39-3.01,1.22-4.06,2.48-.69.83-1.35,1.72-1.67,2.77-.27.9-.26,2-.19,2.97,0,.03,0,.07,0,.1,0,.02,0,.03,0,.05-.04.74-.02,1.48,0,2.23-.17.87-.31,2.01-.13,2.83-.12.64-.22,1.27-.22,1.91,0,1.09-.03,2.06.36,3.09.05.2.1.4.16.6.55,1.78,1.84,3.53,3.2,4.77.38.35.8.64,1.23.92.42.27.83.6,1.28.81.98.46,1.97.33,2.97,0,1.79-.61,3.25-1.92,4.65-3.14.85-.74,1.76-1.42,2.59-2.19.66-.61,1.23-1.29,1.83-1.95.38-.42.67-.7,1.17-.98.49-.27.91-.6,1.3-.99.76-.75,1.49-1.79,1.61-2.88.1-.85-.01-2.61-1.29-2.25Z" fill="#eec3ae" stroke-width="0"/>
            <g>
              <path d="M30.65,29.58c0,1.86-1.51,3.38-3.38,3.38s-3.38-1.51-3.38-3.38,1.51-3.38,3.38-3.38,3.38,1.51,3.38,3.38Z" fill="none" stroke="#242449" stroke-miterlimit="10" stroke-width=".48"/>
              <path d="M40.55,30.23c0,1.86-1.51,3.38-3.38,3.38s-3.38-1.51-3.38-3.38,1.51-3.38,3.38-3.38,3.38,1.51,3.38,3.38Z" fill="none" stroke="#242449" stroke-miterlimit="10" stroke-width=".48"/>
              <path d="M30.65,29.58s.48-.45,1.22-.4c.74.05,1.71-.12,1.93,1.04" fill="none" stroke="#242449" stroke-miterlimit="10" stroke-width=".48"/>
            </g>
            <path d="M48.32,25.98c-.46-2.91-.7-5.02-2.87-7.25-1.15-1.19-2.41-2.33-4.04-2.76-.69-.18-1.4-.25-2.11-.26.07-1.25-.87-2.02-1.9-2.15-.44-.19-.97-.23-1.56,0-1.93.74-3.63,1.34-5.34,2.57-1.06.76-3.09,2.1-3.53,3.4-.61,1.79.86,3.48,2.73,2.9,2.08-.65,4.1-.82,6.04-1.99.19-.11.37-.23.55-.36.3.03.61.04.95.03,1.48-.04,2.73-.3,4.05.58,2.3,1.53,2.36,3.34,2.74,5.74.43,2.73,4.72,2.3,4.29-.46Z" fill="#edb34e" stroke-width="0"/>
          </g>
        </g>
        <g class="person" v-else-if="index === 2">
          <path d="M28.27,45.18s-13.95,2.25-14.2,9.69l-1.12,12.15s21.17,8.31,21.5,8.43,28.6-11.78,28.6-11.78c0,0-2.67-15.45-22.42-20l-12.36,1.51Z" fill="#ce2f49" fill-rule="evenodd" stroke-width="0"/>
          <path d="M40.63,43.68c-2.63-.76-2-2.91-1.41-7.25l-7.88-.28c.17,5.06-.41,8.03-3.08,9.03,0,0,.51,4.11,5,4.36s7.37-5.87,7.37-5.87Z" fill="#eec3ae" fill-rule="evenodd" stroke-width="0"/>
          <g>
            <path d="M26.49,29.08c.12,1.34.41,2.34.95,3.62.75,1.77,1.44,2.79,2.4,3.91,1.41,1.65,2.38,2.54,3.47,2.73,1.07.19,2.24-.29,4.48-1.46,1.1-.57,1.99-1.24,3.18-2.26,1.21-1.04,2.05-2.09,2.86-3.52.85.27,1.36-.34,1.8-1.23.66-1.35,1.6-2.65,2.14-3.84.6-1.3-.07-2.28-1.08-1.7.32-1.26.52-2.61.59-4.27.23-5.28-2.23-7.91-8.19-8.97-5.38-.95-9.46,1.93-11.32,6.33-.5,1.18-.8,2.47-.99,3.8,0,0-1.25,3.25-.29,6.86Z" fill="#eec3ae" fill-rule="evenodd" stroke-width="0"/>
            <path d="M38.99,12.08l.11.02c5.96,1.05,8.42,3.69,8.19,8.97-.07,1.66-.27,3.01-.59,4.27,1.01-.58,1.67.39,1.08,1.7-.55,1.19-1.49,2.49-2.14,3.84-.43.89-.95,1.5-1.8,1.23-.81,1.42-1.65,2.48-2.86,3.52-1.19,1.02-2.08,1.69-3.18,2.26-2.23,1.16-3.41,1.64-4.48,1.46h-.05s5.72-27.26,5.72-27.26Z" fill="#eec3ae" fill-rule="evenodd" stroke-width="0"/>
          </g>
          <path d="M26.53,20.71c3.04.64,6.71,1.58,9.69,2.3l.97-2.95-.65,3.03c2.08.5,4.17,1.01,6.27,1.48l1.71-6.83-1.17,6.95c1.53.34,3.07.66,4.63.96,3.2-10.56-3.19-15.35-7.67-16.07-4.06-.65-11.75-.29-13.76,11.14Z" fill="#25244b" fill-rule="evenodd" stroke-width="0"/>
          <path d="M55.38,56.74c.59-1.43.28-3.26-.34-4.62-.02-.04-.04-.08-.06-.12,1-2.73,4.97-4.74,5.69-7.8,1.01-4.27-3.91-6.28-3.27-10.36.54-3.39,1.34-6.47-.62-9.64-1.65-2.66-3.95-3.05-6.42-3.18-.34-.1-.69-.17-1.06-.19-.28,0-.53.03-.76.11-.74-.03-1.5-.08-2.24-.21-2.53-.41-2.8,3.56-.3,3.96.89.14,1.76.07,2.63.03.25.08.51.16.78.24.73.6,1.11,2.56,1.08,3.14-.05.97-.38,1.6-.94,2.36-1.06,1.45-2.59,2.42-3.85,3.65-2.19,2.13-1.79,3.98-.33,6.3.54.85.81.17.81,1.31,0,.52-.5,1.22-.6,1.77-.24,1.26-.07,2.21.38,3.42.6,1.61,1.47,3.24,2.62,4.52.46.52,1.44,1.09,2.19,1.76.05,1.35.38,2.68.96,3.77.83,1.55,3.01,1.34,3.65-.23Z" fill="#242449" stroke-width="0"/>
        </g>
        <g class="person" v-else-if="index === 3">
          <path d="M19.97,87.37l-9.78-1.33s7.56-24.33,14.97-26.54c7.41-2.22,3.48,13.87,1.85,19.28-1.63,5.41-3.9,8.99-3.9,8.99l-3.15-.39Z" fill="#ce2f49" stroke-width="0"/>
          <path d="M31.27,55.85s-1.64.54-6.64,4.01c-5,3.46-8.58,23.75-8.1,32.05.52,9.07-1.31,42.14-1.31,42.14l41.64-2.12s.5-11.75,0-27.7c-.47-15.19-4.77-47.98-6.04-48.37-6.39-1.96-19.55,0-19.55,0Z" fill="#ce2f49" stroke-width="0"/>
          <path d="M48.31,81.55c.58-6.39-3.97-24.14.24-25.4,6.31-1.9,13.03,5.4,14.48,16.39.73,5.49,1.3,14.5-1.21,20.18-4-1.04-10.66-4.86-14.57-5.91.53-1.62.9-3.37,1.07-5.25Z" fill="#ce2f49" stroke-width="0"/>
          <g>
            <path d="M50.73,56.92s-7.02-6.44-6.44-20.77l-7.75,6.16-5.26,4.18s0,2.49.55,6.85c.14,1.15.82,5.7,1.05,7.1-2.03,1.1,2.49,2.78,7.05,1.33,5.07-1.62,10.81-4.84,10.81-4.84Z" fill="#eec3ae" stroke-width="0"/>
            <path d="M31.27,46.49s0,2.49.55,6.85c5.81-.96,12.45-10.29,12.45-10.29-2.95-.87-5.53-1.04-7.73-.75l-5.26,4.18Z" fill="#eec3ae" stroke-width="0"/>
            <path d="M25.93,45.42s15.48,6.23,19.21-3.47c3.73-9.7,8.24-15.43-1.48-19.82-9.73-4.39-13.08-1.43-15.12,1.5-2.03,2.93-6.55,19.91-2.61,21.78Z" fill="#eec3ae" stroke-width="0"/>
            <path d="M45.37,40.99s-1.59-.64-.82-2.85c.77-2.21,1.42-5.18-.55-5.04-1.97.14-2.39,1.6-2.96.57-.57-1.03.22-4.67-5.21-6.14-5.44-1.47-8.1-.05-10.4-1.74s-1.58-5.59-1.58-5.59c0,0,.42,1.54,1.42,2.22,0,0-2.97-6.78,2.85-9.68,5.82-2.91,10.28.83,13.09,1.63,6.21,1.78,10.19,3.05,11.33,7.65,1.14,4.59-2.25,15.89-7.17,18.97Z" fill="#d45a2e" stroke-width="0"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style scoped lang="scss">
svg.hidden .person path {
  fill:black;
}
</style>