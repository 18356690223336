<template>
  <div class="container other-help pt-5 pb-5 text-center">
    <h2 class="font-weight-bold mb-5">Other ways we can help</h2>
    <div class="row align-items-stretch px-3 px-md-0" style="gap:1rem;">
      <div
          v-if="lifeInsurance"
          class="col p-5 border border-success rounded d-flex flex-column align-items-center justify-content-between"
      >
        <img src="@/assets/sections/family-sec.svg" alt="Life Insurance" class="img-fluid" />
        <h2 class="font-weight-bold">Life Insurance</h2>
        <p>If you were to die, how would your loved ones cope financially? Life insurance takes away the worry, paying out a tax-free lump sum in the event of your death.</p>
        <button class="btn btn-xl btn-secondary ctaButton py-3"
                type="button"
                name="button"
                @click="$emit('click','lifeInsurance')">
          Get a quote
        </button>
      </div>
      <div
          v-if="incomeProtection"
          class="col p-5 border border-success rounded d-flex flex-column align-items-center justify-content-between"
      >
        <img src="@/assets/sections/family-sec.svg" alt="Income Protection" class="img-fluid" />
        <h2 class="font-weight-bold">Income Protection</h2>
        <p>If you were to die, how would your loved ones cope financially? Life insurance takes away the worry, paying out a tax-free lump sum in the event of your death.</p>
        <button class="btn btn-xl btn-secondary ctaButton py-3"
                type="button"
                name="button"
                @click="$emit('click','incomeProtection')">
          Get a quote
        </button>
      </div>
      <div
          v-if="criticalIllness"
          class="col p-5 border border-success rounded d-flex flex-column align-items-center justify-content-between"
      >
        <img src="@/assets/protect-your-family/step-3.svg" alt="Critical Illness" class="img-fluid" />
        <h2 class="font-weight-bold">Critical Illness Protection</h2>
        <p>If you were to fall critically ill, how would you and your family cope financially? With critical illness cover you get a tax-free lump sum to help you adjust.</p>
        <button class="btn btn-xl btn-secondary ctaButton py-3"
                type="button"
                name="button"
                @click="$emit('click','criticalIllness')">
          Get a quote
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherHelp",
  props: {
    lifeInsurance: {
      type: Boolean,
      default: false
    },
    incomeProtection: {
      type: Boolean,
      default: false
    },
    criticalIllness: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.other-help img {
  max-height:200px;
}
.other-help .row .col {
  gap: 1rem;
  border-radius: 1rem !important;
  border-width: 2px !important;
}
.ctaButton {
   font-size:1.25rem;
   font-weight: bold;
   border-radius: 2rem;
 }
</style>