<template>
  <div>
    <div :class="`container-fluid contact ${ !noBackground ? `background` : ''}`">
      <div class="container pt-4">
        <div class="row">
          <div class="col-12 col-md-6">
            <h1 class="title">We compare insurance from our panel of the UK’s top providers to get you the right policy.</h1>
            <button @click="scrollTop"
                    class="btn btn-secondary d-flex mb-4 ctaButton mt-5 mx-auto mx-sm-0"
                    type="button"
                    name="button">Request a Quote</button>
          </div>
          <div class="col-12 col-md-5 offset-md-1">
            <h4 class="overline">Believe</h4>
            <h2 class="title">Contact Us</h2>
            <p class="copy">
              <template v-for="(item, index) in branding.openingHours">
                {{ item  }}
                <template v-if="index < branding.openingHours.length - 1">
                  <br :key="index"/>
                </template>
              </template>
            </p>
            <p class="copy">Call from a mobile or Landline:</p>
            <h2 class="telNo"><a class="rulertel" :href="`tel:${branding.phone.replaceAll(' ', '')}`">{{ branding.phone }}</a></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {branding} from "@/branding";
export default {
  data() {
    return {
      branding: branding
    }
  },
  props: {
    noBackground: {
      type: Boolean,
      default: true
    }
  },
  methods: {
      scrollTop() {
          window.scrollTo(0, 0);
      }
  }
}
</script>

<style lang="scss" scoped>
.contact {

  &.background {
    background-color: rgba(107, 179, 198, 0.2);
  }

  h1 {
    font-size: 2rem;
  }

  button {
    font-size: 1.2rem;
    border-radius: 2rem;
    padding: 0.8rem 2.5rem;
  }

}
</style>