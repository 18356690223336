<template>
    <div :class="`action-section container-fluid pt-5 pb-5 ${ !noBackground ? `background` : ''}`">
      <div class="container text-center">
        <h1 class="title pb-3">{{ title }}</h1>
        <slot></slot>
        <button :class="`btn btn-secondary ctaButton px-5 py-2 py-lg-3 mt-3 `"
                type="button"
                name="button"
                @click="onButtonClicked">
          {{ buttonText }}</button>
      </div>
    </div>
</template>

<script>
export default {
  name: "ActionSection",
  props: {
    title: {
      type:String,
      required: true
    },
    buttonText: {
      type:String,
      required: true
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },
  methods: {
      onButtonClicked() {
        this.$emit("onButtonClicked");
      }
  }
}
</script>

<style lang="scss" scoped>
.action-section {

  &.background {
    background-color: rgba(107, 179, 198, 0.2);
  }

  & .leading {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
  }

  & .ctaButton {
    font-size:1rem;
    font-weight: bold;
    border-radius: 2rem;
  }

}
</style>