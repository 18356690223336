<template>
  <div/>
</template>
<script>
import {branding} from "../branding";

const loader = new Promise((resolve, reject) => {
    if (window.ClickAppForm) {
        resolve(window.ClickAppForm);
        return;
    }
    const script = document.createElement("script");
    script.src = process.env.VUE_APP_CLICK_APPLY_WIDGET;
    script.crossOrigin = "anonymous";
    script.onload = () => {
        resolve(window.ClickAppForm);
    };
    script.onerror = reject;
    document.body.appendChild(script);
});

export default {
    props: {
      mode: String,
      ownHome: Boolean
    },
    async mounted() {
        const tracking = await window.ClickAppTrack;
        tracking.applicationURL = location.href;
        await loader.then(ClickAppForm => {
            ClickAppForm.default(this.$el, {
                leadSource: branding.shortName,
                phoneNumber: branding.phone,
                documentURLs: {
                    privacyNotice: "/privacy",
                    terms: "/terms"
                },
                tracking: tracking,
                initialValues: {
                  ...this.$route.query,
                  "residentialStatus": this.ownHome ? "homeowner" : "privateTenant",
                  "productType": this.mode},
                mode: "Protect",
                target: "protect",
                media: this.mode === "incomeProtection" ? process.env.VUE_APP_MEDIA_CODE_INCOME_PROTECTION :  process.env.VUE_APP_MEDIA_CODE,
                believe: true,
                redirectOnComplete: "/thank-you"
            });
        }).then(() => window.addEventListener("message", event => {
           if (event.data.type === "scroll") {
              window.scrollTo(0, 0);
            }
        }));
    }
};
</script>
