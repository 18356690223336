<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="home.png"
        title="Get the right cover for you."
        leading="Whatever life throws at you, we make it easy to stay protected. Our expert insurance
        advice is designed around you and your loved ones, and our free quotes make it easy to get
        great cover at a price that’s right."
        :points="[
            `Free review from specialist insurance advisers`,
            `Access to the UK’s leading insurance providers`,
            `Cover available for 180 different serious illnesses`,
            `Non-medical plans available if you have pre-existing medical conditions`
            ]"
    >
    </Hero>
    <TrustPilot />
    <InfoSection
        image="searching-sec"
        title="We work hard - you get the best deal."
        :swap="true"
    >
      <p>Buying life insurance, income protection and critical illness cover can be complicated.</p>
      <p>By combining our own powerful, bespoke technology with our friendly team of expert advisors,
        we’re able to search out competitive quotes that are right for you and your needs and give you plenty of personal advice too.</p>
      <p>We talk through your circumstances, guide you through the protection options, and help get you protected quickly and
        easily. So, whatever your reasons for wanting to prepare for the unexpected, we’ll help you get the cover you need.</p>
    </InfoSection>
    <ProtectYourFamily @on-button-clicked="(v) => $refs.hero.showAppForm(v)" />
    <HowItWorks/>
    <FAQs/>
    <Contact :no-background="false" />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
import Providers from '../Sections/Providers.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import ProtectYourFamily from "../Sections/ProtectYourFamily";
import FAQs from "../Sections/FAQs";

export default {
  name: 'app',
  components: {
    ProtectYourFamily,
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Providers,
    Footer,
    FAQs
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
}
</script>
