<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="rental-cover.png"
        title="You may not be a homeowner, but your house is still your home"
        leading="Protect the roof over your head with rental cover for tenants."
        :points="[
            `Protect rent payments in case you lose your income`,
            `Cover your bills if you can't work due to unemployment or illness`,
            `Valuable income protection for you and your family`
        ]"
    >
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-5 mb-4 d-block d-md-none btn-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('incomeProtection', false)">
        Request a quote
      </button>
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-5 mb-md-4 mb-0 d-none d-md-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('incomeProtection', false)">
      Request a quote
      </button>
    </Hero>
    <TrustPilot />
    <InfoSection
        :swap="true"
        image="family-home"
        title="The importance of rental cover"
    >
      <p>
        Your rent is one of the most important bills you pay. It gives you and your family a home where you can grow together and make memories to last a lifetime. But if you lose your work or hit financial trouble, how will you cope?
      </p>
      <p>
        With rental cover, you can rest assured that your rent and other bills are protected even if you lose your income.
      </p>
    </InfoSection>
    <Statistics/>
    <RentalCover @click="$refs.hero.showAppForm('incomeProtection', false)" />
    <InfoSection
        image="searching-sec"
        title="We work hard to find you the best deal
"
    >
      <p>
        We work with some of the UK’s leading insurers to find competitive deals tailored to you and your circumstances.
      </p>
      <p>
        With just a few simple questions, it doesn’t take long to get protected – and you can carry on enjoying life in your precious home.
      </p>
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 d-none d-md-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('incomeProtection', false)">
        Request a quote
      </button>
    </InfoSection>
    <HowItWorks />
    <OtherHelp
      life-insurance
      critical-illness
      @click="(v) => $refs.hero.showAppForm(v, false)"
    />
    <Contact :no-background="false" />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'
import InfoSection from "../Sections/InfoSection";
import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import Contact from '../Sections/Contact.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import Providers from "../Sections/Providers";
import Statistics from "../Sections/Statistics.vue";
import RentalCover from "../Sections/RentalCover.vue";
import OtherHelp from "../Sections/OtherHelp.vue";

export default {
  name: 'IncomeProtection',
  components: {
    OtherHelp,
    RentalCover,
    Statistics,
    Providers,
    InfoSection,
    HowItWorks,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from.name === null) {
        localStorage.setItem('rentalCover', 'landed');
      }
    });
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
