<template>
  <div class="terms-and-conditions">
    <Header />

    <div class="terms-and-conditions__content">
      <h3>Terms and Conditions</h3>

      <p>Our Terms &amp; Conditions explain other aspects of the way we operate and how this affects you. Please read it in conjunction
        with our initial disclosure, <strong>Terms of Business letter</strong> and our <a href="/privacy">Privacy Policy</a>.</p>

      <h5>About us</h5>
      <p>{{ branding.name }} (FCA no. {{ branding.registration.fca.number }}) are an Appointed Representative of {{ branding.appointed.name }}, (FCA no. {{ branding.appointed.registration.fca.number }}) who
        are authorised and regulated by the Financial Conduct Authority.</p>

      <p>{{ branding.appointed.name }} (Company number {{ branding.appointed.registration.company.number }}) Registered address is {{ branding.appointed.address }}.</p>

      <p>{{ branding.name }} (Company number {{ branding.registration.company.number }}) Registered address is {{ branding.address }}.</p>

      <p>You can find out more about us on our website at <a :href="branding.website.url">{{ branding.website.description }}</a> .</p>
      <p>{{ branding.name }} &amp; {{ branding.appointed.name }} is also part of the {{ branding.groupName }}.</p>

      <p>As joint controllers of your data, {{ branding.appointed.name }} and {{ branding.name}}
        comply with the Data Protection Laws in the UK and the UK General Data Protection Regulation.
        {{ branding.name }} ICO register number {{ branding.registration.dataprotection.number }}</p>

      <h5>Our Services</h5>
      <p>Our permitted business includes advising on and arranging mortgages, consumer credit activities and insurance mediation. You can check this
        on the FCA register by visiting the website <a href="https://www.fca.org.uk/Firms/systems-reporting/register">www.fca.org.uk/Firms/systems-reporting/register</a>
        or by contacting the FCA on <a href="tel:08001116768">0800 111 6768</a>.</p>

      <h5>Fees &amp; commissions</h5>
      <p>
        We arrange policies with the insurers on your behalf and we do not charge a fee for doing this.
        We will receive a commission from the insurer which is a percentage of the total annual premium.
        A list of commission fee rates payable by the providers we have access to is available on request.
      </p>

      <h5>Anti-money laundering checks &amp; disclosure</h5>
      <p>All transactions relating to our services provided are covered by, and adhere to, the Money Laundering regulations and the
        Proceeds of Crime Act. Our responsibilities include, but are not limited to, verifying the identity and address of our
        customers and no application will be submitted to a Lender until such verification has been obtained.</p>
      <p>This process involves obtaining verification of your identity using documents such as your Passport or Driving Licence and
        for your address a utility bill, bank statement etc. Identity verification checks may include electronic searches through the
        Electronic Identification Verification Service, electoral roll and use of credit reference agencies. Consent for us carrying
        out the appropriate verification checks will be obtained from you and stored on our system. Depending on the level of
        verification generated we may need to contact you for additional information or documentation.  We cannot accept responsibility
        in instances where placing business is delayed due to these regulations.</p>
      <p>We reserve the right to make further enquiries into the source of your funds or wealth to ensure we meet our
        regulatory obligations. We will require proof of your income and expenditure to satisfy these requirements.</p>
      <p>We may check the details you supply against those held on several specific databases. Online verification credit reference
        agencies have access to, for example information from the Electoral Register and fraud prevention agencies. Scoring methods
        can be used in the verification process as this gives a more thorough check of the available data. A record of this process
        will be kept that may be used to help other companies to verify your identity.</p>
      <p>It is important that the information you provide is both accurate and honest, and a true reflection of your circumstances
        If false or inaccurate information is provided, or if a reasonable suspicion of fraud or any other criminal offence is identified,
        we may make the appropriate search using a reputable online credit reference agency and will keep a record of this search in
        line with the anti-money laundering rules and the General Data Protection Regulation. We will pass information to organisations
        involved in fraud prevention to protect ourselves and our customers from theft and fraud, such as fraud prevention agencies,
        law enforcement agencies and regulatory bodies who may access and use this information to prevent fraud and money laundering.</p>
      <p>It is important to disclose relevant information, or change of circumstances, to a provider.  If you fail to disclose
        any material information (e.g., information about any previous claims or medical history) to your insurance provider,
        this could invalidate your insurance cover and could mean that part or all a claim may not be paid.
      </p>

      <h5>Our Advice</h5>
      <p>
        It is very important that you let us know if anything has changed since we last spoke, or if anything changes up to the date when the new policy starts.
        This includes your occupation / role at work, your state of health and any plans to participate in any hazardous activities.  If this happens, the advice may change, and the recommended policy may need to be reviewed.
        Your application may be subject to medical underwriting which could involve having a medical examination.  We will inform you if this is required. Subject to the outcome of underwriting, the premium may be higher than quoted or further exclusions applied however, the policy will not start until we have received your confirmation to proceed.
        </p>
      <p>
        It is important that you read the contract documents carefully when received, including the policy schedule, to check the policy is suitable for you.
      </p>
      <p>
        We explain some of the main benefits, exclusions, limitations, and conditions of the policy. However, you should read the Key Facts document, policy schedule and other policy documents from the Provider to make sure you fully understand the policy and are eligible to make a claim.
      </p>
      <p>
        We explain the importance of disclosing all material information in the application form. If you fail to disclose any material information (e.g., information about any previous claims or medical history) to your insurance provider, this could invalidate your insurance cover and could mean that part or all a claim may not be paid.
      </p>
      <p>
        Decreasing Term Life or Critical Illness Insurance: The amount of cover will reduce during the period of cover. Decreasing cover is often used to help protect a repayment mortgage or second charge loan. An interest rate is applied to the original amount of cover to estimate the amount that you repay each month on your repayment mortgage or second charge loan and the amount you are covered for will decrease accordingly. If the interest rate applied is less than the interest rate for your mortgage or your second charge loan, or your mortgage or second charge loan rate changes, the amount the insurer pays out in the event of a claim may not be enough to repay your mortgage or second charge loan in full. The interest rate used for your life insurance and / or critical illness quote will be shown in your illustration.
      </p>
      <p>
        We discuss your income and establish that the premium for your new policy is affordable and within your monthly budget and you anticipate that this will continue to be the case in the foreseeable future.
      </p>
      <p>
        The policy has no cash in or surrender value. If you stop the policy, you will not get back any premiums paid to date. If you stop paying premiums, your cover will lapse. If you took out the policy to cover specific liabilities, these will no longer be protected.
      </p>
      <p>
        The maximum amount payable following a claim may be less than the amount required to pay off your mortgage or loan if interest rates rise above a certain level. Please refer to the Key Facts Documents for further details.
      </p>
      <p>
        Please note you should not cancel any existing insurance policies until your new policy is fully in force. Doing so could leave you without cover and unable to claim.
      </p>
      <p>
        Please inform the provider if you change occupation as this could affect your entitlement to cover.
      </p>
      <p>
        You have the right to cancel in accordance with the terms set out in the Policy Summary.
      </p>
      <p>
        If you need to make a claim under a policy, you should contact the Insurance Provider. The claim notification requirements are set out in the Policy Summary.
      </p>
      <p>
        No advice has been given concerning Trusts; however, we do inform you of the options to write a policy into trust. If a policy is not written in trust the insurance proceeds may not be paid to the person(s) intended & may also increase any potential inheritance tax liability on your death. If you require any advice regarding placing a policy in trust, then you should speak to a legal adviser first.
      </p>
      <p>
        We will not provide an ongoing service related to this contract.
        To ensure that these recommendations remain relevant to your circumstances we recommend that you contact us every 12 months to discuss any ongoing requirements. However, please feel free to contact us at any time should there be any change to your circumstances.
      </p>

      <h5>Terms of website use</h5>
      <p>Your use of this website constitutes your agreement to all the terms of use detailed below. The information that is made
        available on this website is intended for general information purposes only and is provided on an ‘as is’ basis without any
        warranties of any kind. We do not accept any liability for any loss arising because of reliance on the information contained
        on this website.</p>
      <p>This website is owned by {{ branding.groupName }} and is protected by copyright and database rights. You acknowledge that all
        trademarks (registered or unregistered) used in providing this service shall always remain the property of {{ branding.groupName }}.</p>
      <p>You shall not copy, adapt, exploit, or otherwise use the information contained on this website in any way, save for the
        downloading and temporary storage of one or more of these pages for the purpose of viewing on a personal computer or terminal,
        provided that you do not delete any copyright and other proprietary notices contained therein.</p>
      <p>We may make improvements or changes to the products, services and prices detailed on this website at any time without notice.</p>
      <p>You agree that you will not use this website to: a) Commit or encourage any criminal offence, including but not limited to
        harming a minor in any way and stalking or harassing another; b) Insert or knowingly or recklessly transmit or distribute a
        virus, worm, trojan horse, time bomb, trap door or any other computer code, files or programs or repetitive requests designed
        to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or to
        diminish the quality of, interfere with the performance of or impair the functionality of this website; including carrying
        out any hacking activity; c) Circumvent, or attempt to seek to circumvent, any of the security safeguards of this website;
        d) Permit any third party to do any of the above.</p>
      <p>While we try and ensure that all information on this website is accurate and up to date, errors may occur, and we accept no
        liability for such errors. If we discover an error, we will amend the website as soon as reasonably possible.</p>
      <p>We neither warrant nor represent that your use of this website will not infringe the rights of third parties.</p>
      <p>We are interested in protecting your personal information and we only use your personal information in accordance with
        our Data Protection Policy.</p>
      <p>You have sole responsibility for adequate protection and backup of data and/or equipment used by you in connection with
        this website and will not make a claim against {{ branding.name }} for lost data, re-run time, inaccurate output, work delays
        or lost profits resulting from the use of such materials.</p>
      <p>We may edit this policy from time to time. If we make any substantial changes, we will notify you by posting a prominent announcement on our pages.</p>
      <p>{{ branding.name }} reserves the right to refuse access to this website or any component of it. We may at any time, at the discretion
        of the site’s management, refuse access to certain areas of this website to any individual it chooses – this refusal of
        access may take whatever form the management deem suitable.</p>
      <p>This site is controlled and operated by {{ branding.groupName }} from the United Kingdom. We make no representations that materials
        in this website are appropriate or available for use in other locations. Those who choose to access this website from other
        locations do so at their own risk and are responsible for compliance with all local laws, if and to the extent local laws are
        applicable. No material from this website may be downloaded, exported, or re-exported into (or to a national or resident of)
        any countries that are subject to UK exporting restrictions/legal or socio-economic sanctions.</p>

      <h5>Complaints</h5>
      <p>Our priority is to provide customers with the highest level of service. However, in the unlikely event that you have a complaint, please contact us:</p>
      <ul>
        <li>In writing:	Compliance Team, {{ branding.appointed.name }}, {{ branding.appointed.address }}.</li>
        <li>By phone: <a :href="`tel:${branding.appointed.phone.replaceAll(' ', '')}`">{{ branding.appointed.compliance.phone }}</a></li>
        <li>By email: <a :href="`mailto:${branding.appointed.compliance.email}`">{{ branding.appointed.compliance.email }}</a></li>
      </ul>
      <p>If you cannot settle your complaint with us, you may be entitled to refer it to the Financial Ombudsman Service (FOS).</p>
      <p>Further information on the services provided by the FOS can be found on their website financial-ombudsman.org.uk or alternatively:</p>
      <ul>
        <li>In writing: The Financial Ombudsman Service, Exchange Tower, London. E14 9SR.</li>
        <li>By phone: <a href="tel:08000234567">0800 023 4567</a> or <a href="tel:03001239123">0300 123 9123</a></li>
        <li>Email: <a href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk</a></li>
      </ul>
      <p>We are committed to keeping your information private. We comply with Data Protection laws in the UK &amp; you have several rights. Full
        details on how we process your personal data &amp; details of your rights can be found in our Privacy Policy on our
        website.</p>
      <p>If your complaint is related to how your personal data has been processed and you are not satisfied with the response
        from us, you have the right to complain to the Information Commissioners Office (ICO) who is the regulator for data
        protection in the United Kingdom.</p>

      <h5>Accessing Our Site</h5>
      <p>Access to our Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide
        on our Site without notice (see below). We will not be liable if for any reason our Site is unavailable at any time or for any period.</p>
      <p>From time to time, we may restrict access to some parts of our Site, or the entirety of our Site, to users who have registered with us.</p>
      <p>If you choose, or you are provided with, a user identification code, password, or any other piece of information as part of our
        security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have
        the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our
        opinion you have failed to comply with any of the provisions of these Terms of Use.</p>
      <p>You are responsible for making all arrangements necessary for you to have access to our Site. You are also responsible
        for ensuring that all persons who access our Site through your internet connection are aware of these terms, and that they comply with them.</p>
      <p>Any personal data we process about you will be processed in accordance with our Privacy Policy and by using our Site to
        transmit personal data you consent to such processing, and you warrant that information provided by you is accurate.</p>
      <p>Additionally, by using our Site, you acknowledge and agree that Internet transmissions are never completely private or secure. You
        understand that any message or information you send to our Site may be read or intercepted by others, notwithstanding the
        Company’s efforts to protect such transmissions.</p>

      <h5>Law and Jurisdiction</h5>
      <p>Your use of our Site and these Terms of Use are governed by the Laws of England and Wales whose courts have exclusive
        jurisdiction of any disputes that may arise under or in connection with the Terms of Use.</p>
      <p>However, we retain the right to bring proceedings against you for breach of these Terms of Use in your country of
        residence or other relevant country.</p>
      <p>All the insurance products we intermediate are governed and construed in accordance with the laws of England
        and Wales, in relation to any dispute. For your protection you agree to submit to the non-exclusive jurisdiction of the English courts.</p>

      <h5>Cookie policy</h5>
      <p>When using our websites, you can set your browser to refuse all or some browser cookies, or to alert you when websites set or
        access cookies. If you disable or refuse cookies, please note that some parts of the websites may become inaccessible or not
        function properly. For further information about the cookies we use, please see our cookie policy.</p>

      <h5>Further information</h5>
      <p>If you have any questions relating to our Terms &amp; Conditions please contact the Compliance Department, {{ branding.appointed.name }},
        {{ branding.appointed.address }} or email <a :href="`mailto:${branding.appointed.compliance.email}`">{{ branding.appointed.compliance.email}}</a>.</p>
      <p>It may be necessary to update this policy to meet legal, regulatory, and commercial requirements. Please visit this page from
        time to time and take note of any changes.</p>

    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '../../Sections/Footer'
import Header from '../../Sections/Header'
import {branding} from "@/branding";
export default {
  name: "TermsAndConditions",
  components: {
    Header,
    Footer
  },
  data () {
    return {
      branding
    }
  }
}
</script>

<style>
.terms-and-conditions {
  color: #000;
}

.terms-and-conditions__content h5 {
  font-weight: bold;
}

.terms-and-conditions__content h3 {
  margin-bottom: 1rem;
}

.terms-and-conditions__content table {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.terms-and-conditions__content {
  max-width: 1140px;
  margin: 118px auto 20px auto;
  position: relative;
  padding: 10px 15px;
}

.terms-and-conditions__content ul {
  list-style-type: disc;
}

.terms-and-conditions__content td {
  padding: 5px;
}
</style>
