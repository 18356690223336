
export const branding = {
    name: "Believe Protect Ltd",
    shortName: "Believe Protect",
    website: {
        url: "https://www.believeprotect.co.uk",
        shortUrl: "believeprotect.co.uk",
        description: "www.believeprotect.co.uk"
    },
    phone: "01302 590 852",
    address: "Believe House, Heavens Walk, Doncaster, DN4 5HZ",
    openingHours: ["Monday - Thursday 9am - 7pm", "Friday 9am - 3pm"],
    trustPilot: {
        businessUnitId: ""
    },
    registration: {
        fca: {
            number: "973843",
            url: "https://register.fca.org.uk/s/firm?id=0014G00002wFZNfQAO"
        },
        company: {
            number: "13913093",
            url: "https://beta.companieshouse.gov.uk/company/13913093"
        },
        dataprotection: {
            number: "ZB303456",
            url: "https://ico.org.uk/ESDWebPages/Entry/ZB303456"
        }
    },
    group: "Believe Finance, Believe Specialist Finance, Believe Loans, Believe Protect and Simply Money part of the Believe Money Group",
    appointed: {
        name: "Believe Advisor Limited",
        address: "Believe House, Heavens Walk, Doncaster, DN4 5HZ",
        phone: "01302 590 852",
        compliance: {
            email: "compliance@believeadvisor.com",
            phone: "01302 591 293"
        },
        registration: {
            fca: {
                number: "841395",
                url: "https://register.fca.org.uk/s/firm?id=0010X00004T6W1yQAF"
            },
            company: {
                number: "11842506",
                url: "https://beta.companieshouse.gov.uk/company/11842506"
            }
        }
    }
}
