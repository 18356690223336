<template>
  <div class="statistics p-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6">
          <div class="d-flex align-items-center">
            <img src="@/assets/icons/calendar.svg" alt="calendar" class="mb-3 mr-4 d-none d-md-block img-fluid" style="height:70px;">
            <div>
              <h2 class="display-3 font-weight-bold">{{ daysText }}</h2>
              <p class="font-weight-bold">How long the average UK household could get by if it lost its income</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row align-items-center">
            <div class="col-12 col-lg-4">
              <h2 class="display-3 font-weight-bold">{{ percentText }}</h2>
              <p class="font-weight-bold">of UK households have no savings</p>
            </div>
            <div class="col-12 col-lg-8 d-flex flex-wrap" style="gap:0.5rem">
              <Person
                v-for="(person, personIndex) in peopleArray"
                :key="personIndex"
                :index="person.id"
                :hidden="person.hidden"
                width="60px"
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from "../assets/avatar/Person.vue";

export default {
  components: {Person},
  computed: {
    daysText() {
      return `${this.days} ${this.days.length === 1 ? 'day' : 'days'}`;
    },
    percentText() {
      return `${this.percent}%`;
    },
    peopleArray() {
      let people = Array.from({ length: 10 }, () => {
        return {
          id: Math.floor(Math.random() * 3) + 1,
          hidden: false
        }
      });
      for (let i = 0; i < Math.floor(this.percent / 10); i++) {
        people[i].hidden = true;
      }
      return people;
    }
  },
  data() {
    return {
      days: 19,
      percent: 62
    }
  }
}
</script>

<style type="scss">
.statistics {
  background: linear-gradient(
      90deg,
      #1F244A 25%,
      #56b37f 100%
  );
  color:white;
}
.statistics .img.hidden {
  g path {
    fill:black;
  }
}

</style>
