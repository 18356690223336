<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="income-protection.png"
        title="Buying income protection, the easy way">
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 mb-4 d-block d-md-none btn-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('incomeProtection')">
        Request a quote
      </button>
      <p>
        If you’re unable to work due to illness or injury, income protection is a great
        way to ensure you can still cover the cost of your day-to-day bills and living
        expenses. To make the whole process easier for you, our team of expert advisors
        will search some of the UK’s leading insurance providers to find a deal tailored to your needs.
      </p>
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 d-none d-md-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('incomeProtection')">
      Request a quote
      </button>
    </Hero>
    <TrustPilot />
    <InfoSection
        image="motherhome"
        title="Find out more about income protection"
        :swap="true"
    >
      <p><strong>What’s income protection?</strong></p>
      <p>Income protection is a way of making sure you can pay for day to day living costs
        even if you can’t work due to illness or injury. It pays a monthly benefit until
        your able to return to work. Protecting your income means you and your family don’t
        have to worry about where your next mortgage payment is coming from if you can’t work.
      </p>
      <template slot="extra">
        <div class="row pb-4">
          <div class="col-12 col-md-6">
            <p><strong>Types of income protection</strong></p>
            <p>
              There are a couple of different types of income protection, you can have Full
              term cover or Short term cover (Budget) and you can chose how long your Waiting/Deferred period is.
              </p>
            <p>
              Don’t worry about which option is right for you, our team of expert advisors will tailor a product
              that fits your needs and budget.
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p><strong>When you can claim your Income Protection benefit</strong></p>
            <p>
              Income protection will pay out a monthly benefit to help cover the cost of your day-to-day
              bills if you are signed off work for a period of time by a medical professional.
              </p>
            <p>
              Some of the main illnesses that are claimed on are musculoskeletal injuries or conditions
              like a bad back, mental health issues like depression and serious conditions such as heart
              attacks, strokes or cancer.
            </p>
          </div>
        </div>
      </template>
    </InfoSection>
    <InfoSection
        image="working"
        title="Who could benefit from income protection?"
    >
      <p>
        Income protection is normally set up to help you and your family cover the day-to-day
        cost of living if you are unable to work due to illness or injury. If you’re a couple
        or married with shared debts like a mortgage, income protection can be used to pay
        these commitments. If you’re a parent income protection help provide for your children
        when you’re no longer there. If you’re self employed, you won’t have company sick pay
        to rely on so income protection is a great way to give you peace of mind that if your
        can’t work you and your family will still receive and income.
      </p>
    </InfoSection>
    <ActionSection
        title="What’s the right level of cover?"
        button-text="Request a quote"
        @onButtonClicked="scrollTop">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>
            The right level of income protection cover for you depends on your
            circumstances. Simply request a quote and we’ll help you choose
            the right cover at the right price.</p>
        </div>
      </div>
    </ActionSection>
    <FAQs/>
    <HowItWorks />
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'
import InfoSection from "../Sections/InfoSection";
import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import Contact from '../Sections/Contact.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import ActionSection from "../Sections/ActionSection";
import Providers from "../Sections/Providers";
import FAQs from "../Sections/FAQs";

export default {
  name: 'IncomeProtection',
  components: {
    FAQs,
    Providers,
    ActionSection,
    InfoSection,
    HowItWorks,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
