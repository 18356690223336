<template>
  <div :class="`container-fluid faqs ${ !noBackground ? `background` : ''}`">
    <InfoSection
        title="Searching for answers?"
        image="searching-single">
      <p>We have complied a list of frequently asked questions that we get asked that may help you get started with getting the right cover.</p>
      <router-link to="/faqs/critical-illness" class="btn btn-secondary ctaButton px-0 px-md-5 py-2 py-lg-3 mb-4 col-12 col-md-12 col-lg-8 offset-lg-2">
        Frequently Asked Questions
      </router-link>
      <p>The language of life insurance can be confusing, so here’s a simple guide to some of the key terms.</p>
      <router-link to="/life-insurance-a-to-z" class="btn btn-secondary ctaButton px-0 px-md-5 py-2 py-lg-3 mb-4 col-12 col-md-12 col-lg-8 offset-lg-2">
        A to Z of Life Insurance
      </router-link>
      <p>If you have any questions that are not answered here, please feel free to contact us or
        request a quote and we will be happy to help.</p>
    </InfoSection>
  </div>
</template>

<script>
import InfoSection from "./InfoSection";
  export default {
    name: "FAQs",
    components: {
      InfoSection
    },
    props: {
      noBackground: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.faqs {
  color: #17174C;
  &.background {
    background-color: rgba(107, 179, 198, 0.2);
  }
  h3 {
    font-size:1.25rem;
  }
  p {
    font-size:1rem;
  }
  .ctaButton {
    font-size:1rem;
    font-weight: bold;
    border-radius: 2rem;
  }
  @media (max-width: 420px){
    .ctaButton {
      font-size:0.8rem
    }
  }
}
</style>
