<template>
  <div>
    <Header/>
    <div :style="{ 'padding-top': `${headerHeight}px` }" class="page-header">
      <div class="row hero-area" style="height:80vh">
        <div class="container">
        <div class="col-md-5 col-sm-12">
          <div class="hero-block">
            <h4 class="overline">Believe</h4>
            <h2 class="title">Thank you. One of our experts will be in contact shortly </h2>
            <p class="callingFrom">(calling from number {{ branding.phone }}) to run through your quote.</p>
          </div>
        </div>
      </div>
        <div class="col-md-7 col-sm-12 py-5">
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../Sections/Footer'
import Header from '../Sections/Header'
import { Bus } from '../bus'
import { branding } from '../branding'

export default {
  name: "ThankYou",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      branding,
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    if (window.EF) {
      let rentalCover = localStorage.getItem('rentalCover');
      let offerId = 10;
      if (rentalCover !== null && rentalCover === "landed") {
        offerId = 20;
        localStorage.removeItem('rentalCover');
      }
      window.EF.conversion({
        offer_id: offerId,
      });
    }
  }
}
</script>