<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="critical-illness.png"
        title="Critical illness cover helps you prepare for the unexpected">
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 mb-4 d-block d-md-none btn-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('criticalIllness')">
        Request a quote
      </button>
      <p>
        It not nice to think about getting a critical illness, but the reality is, it could
        happen to you. Setting up a critical illness policy would give you and your family
        the piece of mind if you were to fall critical ill you would receive a lump sum
        payment to cover any financial burdens you have.
      </p>
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 d-none d-md-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('criticalIllness')">
      Request a quote
      </button>
    </Hero>
    <TrustPilot />
    <ActionSection
        title="What’s critical illness cover?"
        button-text="Request a quote"
        @onButtonClicked="scrollTop">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>
            Critical illness cover is an insurance policy that pays out a lump sum if you’re
            diagnosed with one of a range of illnesses or conditions like strokes, heart
            attacks and cancer, along with other serious conditions such as multiple sclerosis,
            organ transplants and loss of limbs.
          </p>
        </div>
      </div>
    </ActionSection>
    <InfoSection
        :no-background="true"
        image="family-home"
        title="Who could benefit from critical illness cover?"
        :swap="true"
    >
      <p>If your family rely on you financially, or you have regular financial commitments,
        critical illness cover could cover the cost of those commitments if you were to fall
        critically ill.
      </p>
    </InfoSection>
    <FAQs :no-background="false" />
    <HowItWorks :no-background="true"/>
    <ActionSection
        title="Are you ready to apply?"
        button-text="Let's get started"
        @onButtonClicked="scrollTop">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>Click now to get on the road to great critical illness cover. Your progress will be
            saved so you can come back at any time.</p>
        </div>
      </div>
    </ActionSection>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'
import InfoSection from "../Sections/InfoSection";
import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import Contact from '../Sections/Contact.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import ActionSection from "../Sections/ActionSection";
import Providers from "../Sections/Providers";
import FAQs from "../Sections/FAQs.vue";

export default {
  name: 'IncomeProtection',
  components: {
    FAQs,
    Providers,
    ActionSection,
    InfoSection,
    HowItWorks,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
