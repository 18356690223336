<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="athome.png"
        title="A to Z of Life Insurance">
      <p>
        The language of life insurance can be confusing, so here’s a simple guide to some of the key terms.
      </p>
      <p>
        If you have any questions that are not answered here, please feel free to contact us or request a
        quote and we will be happy to help.
      </p>
    </Hero>
    <div class="faqs container-fluid pt-5 pb-5">
      <div class="container">

        <h2>A</h2>
        <p><strong>Age limits</strong></p>
        <p>
          The maximum or minimum age that an insurer will issue a policy or continue one that’s already
          in place. You need to be aged between 18 and 77 years to apply for our life insurance, and
          between 50 and 80 years to apply for our over 50 cover.
        </p>
        <p><strong>Applicant</strong></p>
        <p>
          The person applying for the insurance policy.
        </p>
        <p><strong>Application</strong></p>
        <p>
          Once you have a quote, you can apply for life insurance by completing an application online
          or over the phone. You’ll usually need to answer questions about your health and lifestyle
          as part of your application.
        </p>

        <h2>B</h2>
        <p><strong>Beneficiary</strong></p>
        <p>
          The person or organisation that will receive the insurance policy payout if you pass away
          or have a terminal illness within the policy term, and the claim is successful.
        </p>
        <p><strong>Benefit</strong></p>
        <p>
          The amount of money paid to the beneficiary when the insured person passes away. The payout
          is sometimes called the death benefit.
        </p>

        <h2>C</h2>
        <p><strong>Cash-in value</strong></p>
        <p>
        Some life insurance policies include a savings feature that means that if the policy is
        cancelled early, the policyholder receives the policy’s cash-in value. We don’t offer
        cash-in value life insurance plans.
        </p>
        <p><strong>Claim</strong></p>
        <p>
        When you apply for a payout from the insurer that held the life insurance policy of the
        person who’s passed away, or has a terminal illness, within the policy term.
        </p>
        <p><strong>Cover amount</strong></p>
        <p>
          This is how much the life insurance will pay out when a claim is successful. Our life insurance
        calculator can help you think about how much cover you need.
        </p>
        <p><strong>Critical illness</strong></p>
        <p>
        Critical illness cover can support you and your loved ones financially if you’re diagnosed
        with a specific illness or medical condition. You’ll receive a tax-free lump sum to help with
        things like bills and treatment costs.
        </p>
        <p>
        You can take out life insurance and critical illness cover at the same time, as they cover
        you for different things. We offer them as separate policies, and you can add critical illness
        cover when you apply for life insurance, if you want both.
        </p>

        <h2>D</h2>
        <p><strong>Death in service benefits</strong></p>
        <p>
          Part of an employer’s benefits package, this gives your beneficiary a tax-free lump sum if you
        pass away while working for the company. You don’t usually have to pay for it, but if you leave
        your job for any reason, you may lose the benefit.
        </p>
        <p>
        This article explains the difference between death in service benefit and life insurance.
        </p>
        <p><strong>Decreasing term life insurance</strong></p>
        <p>
        With decreasing term life insurance, the value of your policy gradually reduces over time until
        it reaches £0, but your premiums stay the same. This tends to be used to cover a mortgage or
        repayment loan.
        </p>

        <h2>E</h2>
        <p><strong>Estate</strong></p>
        <p>
          The property and money left by someone who has passed away.
        </p>
        <p><strong>Executor</strong></p>
        <p>
          A person or company named in your will responsible for handling your estate and carrying out
        your instructions when you pass away. Their duties include arranging the funeral, dealing with
        assets, paying unpaid bills and distributing the estate to beneficiaries.
        </p>

        <h2 class="text-muted">F</h2>

        <h2>G</h2>
        <p><strong>Guaranteed life insurance</strong></p>
        <p>
          A type of life insurance that you’ll be accepted for, regardless of your health. So you won’t
        be turned down, and there’s no medical exam.
        </p>

        <h2 class="text-muted">H</h2>

        <h2>I</h2>
        <p><strong>Income protection</strong></p>
        <p>
          A type of insurance that supports you if you can’t work due to sickness or injury. Income
        protection pays you a fixed monthly amount and usually covers around 80% of your pre-tax salary.
        </p>
        <p><strong>Inflation</strong></p>
        <p>
          When the price of goods and services increases over time. If you’re looking to buy life insurance,
        you can choose to protect your cover from the effects of inflation.
        </p>
        <p><strong>Insurance risk</strong></p>
        <p>
          How likely something that you’re insured for will happen, meaning the insurer must pay out. For
        life insurance, this risk is the possibility that the policyholder will pass away before their
        policy term ends.
        </p>
        <p><strong>Insured person</strong></p>
        <p>
          The named person who is covered by the life insurance policy.
        </p>

        <h2>J</h2>
        <p><strong>Joint life insurance</strong></p>
        <p>
          A policy that covers two people’s lives for one monthly premium. With joint life insurance, the
        policy usually pays out when the first person passes away, and ends after that. If you and your
        partner have separate life insurance policies, the surviving partner’s policy will continue.
        </p>

        <h2 class="text-muted">K</h2>

        <h2>L</h2>
        <p><strong>Length of cover</strong></p>
        <p>
          The amount of time you’ll be covered for by an insurance policy, also called the policy term.
        </p>
        <p><strong>Level term life insurance</strong></p>
        <p>
          With level cover, you pay the same monthly amount throughout the policy’s lifetime, and the cover
        amount stays the same. This insurance has no cash-in value.
        </p>
        <p><strong>Life assurance</strong></p>
        <p>
          Like life insurance, this pays out when the person insured on the policy passes away. The main
        difference is that life insurance is designed to provide cover for a specific time period, while
        life assurance runs for a person’s lifetime.
        </p>
        <p><strong>Life insurance</strong></p>
        <p>
          Life insurance is a policy that pays out if the policyholder passes away while they’re
          covered. Some policies also include a payout for terminal illness.
        </p>
        <p>
          The lump sum might be used to help pay off a mortgage or go towards general living costs. Our
        life insurance calculator can help work out how much protection you might need.
        </p>
        <p><strong>Life insurance company</strong></p>
        <p>
          A provider of life insurance policies, such as Aviva.</p>
        <p><strong>Lump sum</strong></p>
        <p>
        The benefit that a life insurance company pays out in one amount when the insured person passes away.
        </p>
        <h2>M</h2>
        <p><strong>Medical exams</strong></p>
        <p>
          When you apply for life insurance, you’ll be asked about your health and lifestyle. In some cases,
        you may be asked to have a medical exam, but we’ll cover the costs.
        </p>
        <p><strong>Medical referrals</strong></p>
        <p>
          If you’re asked by your insurer to have a medical exam by a nurse or doctor after you apply for life
        insurance, it’s called a referral.
        </p>
        <p><strong>Mortgage protection insurance</strong></p>
        <p>
        A type of term life insurance that’s also called decreasing life cover. A mortgage usually goes
        down over time, and this cover decreases over time, too. So if you pass away during the policy
        term and before the mortgage is paid off, the payout could be enough to help your loved ones pay
        off the rest of the loan and stay in the family home.
        </p>

        <h2 class="text-muted">N</h2>

        <h2>O</h2>
        <p><strong>Over 50 life insurance</strong></p>
        <p>
        A type of life insurance for people over the age of 50. It provides cover for life and lets you
        leave a guaranteed lump sum for your loved ones when you pass away. Our over 50 plan has fixed
        premiums, so you don’t have to worry about having to pay more as you get older. Your payments
        will stop after 30 years or when you’re aged 90, but your cover will continue.
        </p>

        <h2>P</h2>
        <p><strong>Policy anniversary</strong></p>
        <p>
        The anniversary of the date that your policy was issued. You’ll find this in your policy documents.
        </p>
        <p><strong>Policyholder/Policy owner</strong></p>
        <p>
          The person, or people, taking out the life insurance policy.
        </p>
        <p><strong>Power of attorney</strong></p>
        <p>
          A legal document that lets you nominate someone to make important legal and financial decisions
        for you if you’re unable to. If you want someone to act on your behalf, you’ll need to give them
        power of attorney over your affairs.
        </p>
        <p><strong>Pre-existing medical conditions</strong></p>
        <p>
          An illness, injury or disease you have when you take out a life insurance policy, also called an
        existing medical condition. When applying for a life insurance policy, you should answer all questions
        truthfully, to make sure your policy is valid.
        </p>
        <p><strong>Premium</strong></p>
        <p>
          The amount that you pay your insurer for cover. It’s usually paid monthly, but some insurers allow
        annual or twice-yearly payment.
        </p>
        <p><strong>Probate</strong></p>
        <p>
          The process of proving a will and dealing with the estate of a person who has passed away. If you’re
        a named executor in a will, you may need to apply for probate so you can gather their assets, pay
        any unpaid bills, and share out what’s left to the beneficiaries.
        </p>

        <h2>Q</h2>
        <p><strong>Quote</strong></p>
        <p>
          A life insurance quote is an estimate of your premium that’s based on things like the type of life
        insurance, the amount of cover and the length of the policy.
        </p>

        <h2 class="text-muted">R</h2>

        <h2>S</h2>
        <p><strong>Separation option</strong></p>
        <p>
          This is an option when you take out joint life insurance. It lets you split the policy into two if
        you and your partner are no longer together, and then both or either of you can take out a new policy
        without answering any more medical questions.
        </p>
        <p><strong>Single life insurance</strong></p>
        <p>
          A policy for one person, with one cover amount and one payment each month, which pays out if you
        pass away during the policy term.
        </p>
        <p><strong>Sum assured</strong></p>
        <p>
          The total cover amount that an insurance policy will pay out as a lump sum if you pass away, or are
        diagnosed with a terminal illness, within the policy term.
        </p>

        <h2>T</h2>
        <p><strong>Term</strong></p>
        <p>
        The length of time a life insurance policy runs for.
        </p>
        <p><strong>Terminal illness</strong></p>
        <p>
          An illness that you’re not expected to recover from. Most life insurance plans include terminal
        illness benefit that pays out if you’re not expected to live longer than 12 months. Once the payment
        has been made, the insurance policy ends.
        </p>
        <p><strong>Term life insurance</strong></p>
        <p>
          This will pay your beneficiaries a set amount if you pass away during the policy term. You pay a
        regular premium, usually monthly, to your insurer for the duration of that term.
        </p>
        <p><strong>Trust</strong></p>
        <p>
          A way to leave something of value to someone without handing them full control. You can put assets
        like money, investments and property, as well as your life insurance policy, into a trust. This is
        known as writing life insurance in trust, or a policy written in trust. A trust won’t be counted as
        part of your estate when you pass away.
        </p>

        <h2>U</h2>
        <p><strong>Underwriter</strong></p>
        <p>
          A trained expert or company that works out the risk of insuring someone. They decide the amount of
        cover the insured person can get, and how much they should pay for it. In some cases, they may decide
        the risk is too great to insure them.
        </p>
        <p><strong>Underwriting</strong></p>
        <p>
        The process used by an insurer that includes asking applicants health and lifestyle questions, so they
        can work out the financial risk of insuring them, and how much they should pay and be covered for.
        </p>

        <h2 class="text-muted">V</h2>

        <h2>W</h2>
        <p><strong>Whole of life insurance</strong></p>
        <p>
        A policy that lasts the lifetime of the person that’s insured. It’s also known as permanent or
        traditional life insurance.
        </p>
        <p><strong>Will</strong></p>
        <p>
          A legal document that sets out how you’d like your estate to be managed and distributed when you
        pass away. You can name executors in your will to carry this out.
        </p>

        <h2 class="text-muted">X</h2>
        <h2 class="text-muted">Y</h2>
        <h2 class="text-muted">Z</h2>

      </div>
    </div>
    <ActionSection
        title="Get the right cover!"
        button-text="Get covered"
        @onButtonClicked="$router.push('/')">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>Click now to get on the road to great cover. Your progress will be
            saved so you can come back at any time.</p>
        </div>
      </div>
    </ActionSection>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'
import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import Contact from '../Sections/Contact.vue'
import Footer from '../Sections/Footer.vue'
import ActionSection from "../Sections/ActionSection";
import Providers from "../Sections/Providers";

export default {
  name: 'AZLifeInsurance',
  components: {
    Providers,
    ActionSection,
    Header,
    Hero,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
h2 {
  border-bottom: 1px solid #ccc;
}
h2.text-muted {
  border-bottom: none;
}
h2 ~ h2.text-muted::after {
  border-top: 1px solid #ccc;
  padding-top: 0.5rem;
  padding-bottom:0.5rem;
  display: block;
  content: "- No terms -";
  font-size: 1.25rem;
  font-style: italic;
}
</style>
