<template>
  <div>
    <div class="container-fluid lenderRow mt-4 mb-4">
      <div class="container d-flex justify-content-around providersRow align-items-center flex-wrap">
        <img src="@/assets/providers/aegon.jpg" alt="AEGON"/>
        <img src="@/assets/providers/aviva.jpg" alt="Aviva"/>
        <img src="@/assets/providers/legal-and-general.jpg" alt="Legal and General"/>
        <img src="@/assets/providers/lv.jpg" alt="LV"/>
      </div>
      <div class="container d-flex justify-content-around providersRow align-items-center flex-wrap">
        <img src="@/assets/providers/metlife.jpg" alt="Metlife"/>
        <img src="@/assets/providers/one-family.jpg" alt="One Family"/>
        <img src="@/assets/providers/the-exeter.jpg" alt="The Exeter"/>
        <img src="@/assets/providers/vitality.jpg" alt="Vitality"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.providersRow img {
  height: auto;
  max-width: 150px;
}
.providersRow img {
  margin: 10px 10px 10px 0px;
}
</style>