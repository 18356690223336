<template>
  <div>
    <Header />
    <div class="container pt-4">
      <div class="row">
        <div class="col-12 privacy">
          <h1>Privacy Policy</h1>

          <p>{{ branding.name }} (FCA {{ branding.registration.fca.number}}) are an Appointed Representative of {{ branding.appointed.name }} (FCA {{ branding.appointed.registration.fca.number }}) who are authorised and regulated by the Financial Conduct Authority.</p>
          <p>{{ branding.appointed.name }} – Registered address is {{ branding.appointed.address }}. (Company No. {{ branding.appointed.registration.company.number }}).<br/>
            {{ branding.name }} – Registered address is {{ branding.address }}. (Company No. {{ branding.registration.company.number }})</p>
          <p>
            {{ branding.name }} &amp; {{ branding.appointed.name }} are part of the Believe Money Group.
          </p>
          <p>
            You can find out more about us on our website at <a :href="`${ branding.website.url }`">{{ branding.website.description }}</a>.
          </p>
          <p>
            As joint controllers of your data, {{ branding.appointed.name }} and {{ branding.name }} comply with the Data Protection Laws in the UK and the UK General Data Protection Regulation. Our ICA register number is {{ branding.registration.dataprotection.number }}
          </p>
          <p>We are committed to protecting your privacy. Please read this Privacy Policy carefully before using our services together with our <span class="bold">Terms and Conditions</span>.  By using our services, you are accepting and consenting to the practices described in this Privacy Policy, which we may update from time to time.</p>

          <h2>Personal data we collect from you</h2>
          <p>Personal information collected, processed, stored, and transferred may include:</p>
          <ul>
            <li><span class="bold">Identity Data</span> your full name, previous names, date of birth, gender, marital status</li>
            <li><span class="bold">Contact Data</span> home address, email address and telephone numbers.</li>
            <li><span class="bold">Financial Data</span> including payment card details, savings, income &amp; expenditure, pension benefits.</li>
            <li><span class="bold">Technical Data</span> including internet protocol (IP) address, browser type and version and other technology on the devices used to access our websites.</li>
            <li><span class="bold">Profile and Usage Data</span> including feedback and survey responses, and how you use our website and services.</li>
            <li><span class="bold">Marketing and Communications Data preferences</span> in receiving marketing from us and our Group &amp; communication preferences.</li>
            <li><span class="bold">Special Category Data</span> Information relevant to your health i.e., health data and therefore ‘special category data’ may be collected from you where it is identified or disclosed by you to the company. We will only record any such data where it is relevant and limited to what it necessary to provide you with our services or ensure that any third parties are able to provide you with services and with your explicit and informed consent.</li>
          </ul>

          <h2>How is personal data collected?</h2>
          <ul>
            <li>Direct contact by phone, most of our calls are recorded, email, SMS, webchat, and post.</li>
            <li>Automated technologies or interactions with our website, by using the web enquiry form.</li>
            <li>Third parties or publicly available sources such as credit reference agencies, Insurance providers &amp; third party/partner websites.</li>
          </ul>

          <h2>Lawful basis for how we use your personal data we collect from you</h2>
          <p>We will collect information from you in relation to insurance services and rely on the following lawful basis for processing:</p>
          <ul>
            <li><span class="bold">Consent</span> We will always seek your consent to process certain types of information where we are legally required to do so. We use consent to initially engage with you and may communicate through phone, email, SMS &amp; webchat to discuss your requirements. We request consent to provide you with an ongoing service and contact you in the future to keep you informed of other products and services from our Group that may be of interest to you.</li>
            <li><span class="bold">Legitimate Interest</span> To process your application, we may supply your personal information to credit reference agencies (CRAs), and they will give us information about you, such as your financial history. We may do this to check your identity and prevent criminal activity.  CRAs will share your information with other organisations. Your data will also be linked to the data of your spouse, any joint applicants, or other financial associates.</li>
            <li><span class="bold">Performance of a Contract</span> We collect information about you from the Credit Reference Agencies to assess your eligibility for the products you requested.</li>
            <li><span class="bold">Legal obligation</span> we collect proof of your identity and proof of address to verify your identity and complete anti-money laundering checks.</li>
            <li><span class="bold">Contract &amp; legitimate interest</span> to manage our relationship with you and inform you of changes to our products and services and re-engage with you to review your existing needs.</li>
            <li><span class="bold">Legal obligation &amp; legitimate interest</span> to handle case enquiries, complaints, and your requests to exercise your rights.</li>
            <li><span class="bold">Legitimate Interests</span> We may process your personal data for the purposes of our own legitimate interests or for the legitimate interests of our suppliers provided that such processing does not outweigh your interests. For example:
              <ul>
                <li>To comply with our legal obligations.</li>
                <li>Develop and improve our products and services, including for the purpose of quality control and analysis.</li>
                <li>Deliver relevant website and advertisement content to you.</li>
                <li>Administer and protect our business and our website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</li>
                <li>Measure or understand the effectiveness of the advertising we serve to you.</li>
                <li>Use data analytics to improve our website, products/services, marketing, customer relationships and experiences.</li>
              </ul>
            </li>
          </ul>
          <p>You may advise us at any time should you no longer wish us to use your data</p>
          <h2>Third parties who we collect information from or share information with:</h2>
          <ul>
            <li><span class="bold">Our Principal Network</span> {{ branding.appointed.name }}</li>
            <li><span class="bold">Our Group</span> with your consent, we may share your data with {{ branding.group }}.</li>
            <li><span class="bold">Insurance providers on our Panel</span> a list is available on request.</li>
            <li><span class="bold">Third parties</span> who we believe will be able to assist us with your enquiry or application such as trusted partners.</li>
            <li><span class="bold">Introducers</span> if you have been introduced to us from an Introducer, we may share limited information to inform them on the progress of the application.</li>
            <li><span class="bold">External suppliers</span> Specialist IT system providers, e.g., Jibba Jabba, Nivohub.com</li>
            <li><span class="bold">Our Regulators</span> to fulfil our legal obligations, for example with the Financial Conduct Authority or the Information Commissioner’s Office. Sharing of information to verify your identity and comply with Anti Money Laundering legislation, and co-operate with law enforcement, legal proceedings, or regulatory authorities.</li>
            <li><span class="bold">Changes of ownership</span> if we sell, transfer or merge parts of our business or our assets any personal data supplied by you where relevant to that part of the business will be transferred to the new owner or controlling party and will continue to be used under the terms of this policy for the purposes that the personal data was originally supplied to us.</li>
            <li><span class="bold">Software systems</span> we use third party software systems including cloud-based storage, to help us to manage applications, collect credit or debit card payments for our services and to process and verify the personal information you supply to us. All software suppliers store any data in the UK and are subject to UK regulations including the UK Data Protection Regulations and are obliged to keep your details securely and use them only to fulfil the contracted service.</li>
          </ul>
          <p>Please note that the sharing of your information does not entitle such third parties to send you marketing or promotional messages. It is shared for the purpose of ensuring we can adequately fulfil our responsibilities to you. We recommend you also read their Privacy Notice in conjunction with ours which can be found on their own websites.</p>

          <h2>How long will we keep your information for?</h2>
          <p>We only retain your personal data for as long as is necessary and depends on the purpose for which we use it, our obligations, and your actions. We will retain and process your personal information based on our statutory and legal obligations, our regulatory requirements, and our legitimate business interests.</p>
          <p>We may continue to use anonymised data (which does not identify individual users) for data analysis, profiling, and research purposes, for example to gain insights about our users.</p>
          <ul>
            <li><span class="bold">Successful applications</span> records will be retained indefinitely. Information that does not need to be accessed regularly after 6 years, but which still needs to be retained, will be safely archived.</li>
            <li><span class="bold">Withdrawn, incomplete and failed applications</span> 2 years from the date the latest application began.</li>
            <li><span class="bold">Enquiry data obtained from customers &amp; third parties that do not result in an application for any product or service from us or the Group</span> 2 years from the date when the lead was received.</li>
            <li><span class="bold">Future marketing of customer data where consent has been obtained</span> Indefinitely unless customer requests data be removed.</li>
            <li><span class="bold">Complaint’s data</span> 3 years as per FCA guidelines.</li>
          </ul>
          <p>After these retention periods, if there is no other on-going relationship, your personal data will either be securely deleted or anonymised so that it can be used for statistical purposes but without any method of identifying you individually.</p>

          <h2>Data Security</h2>
          <p>Your privacy is important to us. We store this on secure servers so that it can only be viewed by authorised personnel. We do our best to protect your personal data, but we cannot guarantee the security of your data you transmit to us, any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access, loss, or damage. We only process your information within the UK. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

          <h2>Your rights in relation to your information</h2>
          <ul>
            <li><span class="bold">Request access to your personal data</span> This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. You have the right to request copies of all records we hold about you.</li>
            <li><span class="bold">Request correction of the personal data</span> This enables you to have any incomplete or inaccurate data corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
            <li><span class="bold">Request erasure of your personal data</span> This enables you to ask us to delete personal data where there is no reason for us continuing to process it or where you have successfully objected to the processing, where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Please note that we may not always be able to delete the data for specific legal reasons, if applicable we will notify you, at the time of your request.</li>
            <li><span class="bold">Object to processing of your personal data</span> where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which overrides your rights and freedoms.</li>
            <li><span class="bold">Request restriction of processing of your personal data</span> This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
            <li><span class="bold">Request the transfer of your personal data</span> to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
            <li><span class="bold">Withdraw consent at any time</span> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
            <li><span class="bold">Right to complain to the Information Commissioners Office</span> If you are not satisfied with how we respond to your enquiry, you have the right to complain to the ICO, who is the regulator for data protection in the UK. <a href="https://ico.org.uk/make-a-complaint/" target="_blank">https://ico.org.uk/make-a-complaint/</a></li>
            <li><span class="bold">Right to complain to FOS</span> You may also have the right to refer any complaint you make to the Financial Ombudsman Service, which is an independent arbitration service and helps settle disputes between customers and businesses.</li>
          </ul>

          <h2>Website &amp; Website Links</h2>
          <p>We use services such as Google Analytics to track how visitors use our website, to understand trends and how the business works and so that we can improve the user experience. The reports we produce do not identify individuals.</p>
          <p>Should you wish to complete a contact or enquiry form on the website or through our connected portals, we collect and store the personal information supplied to us via secure servers to enable us to fulfil your request. Any personal information you chose to disclose via the website or portals will be processed in accordance with this privacy notice.</p>
          <p>Our website may include links to third-party websites, plug-ins, and applications. If you follow a link to or from our app to a third-party website, you may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements and we do not accept any responsibility or liability for these policies.</p>
          <p>When you leave our website, we encourage you to read the privacy notice of every website you visit.</p>

          <h2>Cookie Policy</h2>
          <p>When using our websites, you can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the websites may become inaccessible or not function properly. For further information about the cookies we use, please see our <router-link to="/cookies">cookie policy</router-link>.</p>

          <h2>Marketing</h2>
          <p>You have the choice to opt-in to receive information on products and services offered by us or our Group, that may be of interest to you and not relate to your specific application.</p>
          <p>We will obtain explicit consent from you either verbally, via our website, by email, or by completion of a consent form and will keep a record of your consent.</p>
          <p>Marketing may include emails, SMS, telephone calls and postal marketing. You can advise us of your preferences on how you would like us to provide marketing.</p>
          <p>We will not provide your personal data to any unconnected third party in connection with marketing.</p>
          <p>You may update your preferences or withdraw your consent at any time by emailing the Compliance Team <a :href="`mailto:${ branding.appointed.compliance.email }`">{{ branding.appointed.compliance.email }}</a> or clicking on the relevant link in email communications you receive from us.</p>
          <p><span class="bold">We will never sell your information to third parties.</span></p>

          <h2>How to contact us</h2>
          <p>If you have any questions or complaints relating to how we use your personal data, or if you wish to exercise any of your rights please contact the Compliance Team, {{ branding.appointed.name }}, {{ branding.appointed.address }} or email <a :href="`mailto:${ branding.appointed.compliance.email }`">{{ branding.appointed.compliance.email }}</a>.</p>
          <p>We shall reply as soon as possible and depending on the complexity of the request aim to fulfil your request no later than one month from receiving it. If we need additional time, we will tell you and give you the reasons why.

          <h2>Changes to our Privacy Policy</h2>
          <p>It may be necessary to update this privacy policy to meet legal, regulatory, and commercial requirements. Please visit this page from time to time and take note of any changes. Any personal data collected prior to any change will not be affected by the change without your permission.</p>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '../../Sections/Footer'
import Header from '../../Sections/Header'
import {branding} from "@/branding";

export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      branding
    }
  }
};
</script>

<style lang="scss">
.privacy {
  color: #212529;
  margin-top: 100px;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  table {
    width: 50%;
    margin-left: 10%;
    margin-bottom: 1rem;

    @media (max-width: 599px) {
      width: 100%;
      margin-left: 0;
    }

    tr {
      word-break: break-word;
    }

    td {
      padding: 0.5rem;
      border: 1px solid;
    }

    td:first-of-type {
      min-width: 30%;
    }
  }

  .bold {
    font-weight:bold;
  }

}
</style>
