<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="athome.png"
        title="Frequently asked questions">
      <img :src="`${require(`@/assets/heros/mobile/faqs.svg`)}`"
           class="img-fluid d-block d-md-none mx-auto"/>
      <p>
        Here are some frequently asked questions that we get asked that may help you get started with
        getting the right cover.
      </p>
      <p>
        If you have any questions that are not answered here, please feel free to contact us or request a quote and we will be happy to help.
      </p>
    </Hero>
    <div class="faqs container-fluid pt-0 pt-md-4 pb-5">
      <div class="container">

        <Nav/>

        <p><strong>How has the coronavirus pandemic affected critical illness cover?</strong></p>
        <p>
          You can still get critical illness cover, but there may be delays with new applications
          if any medical evidence is needed. You may also be asked whether you’ve undergone
          treatment in hospital for COVID-19 or had any of the symptoms associated with coronavirus recently.
        </p>
        <p>
          Critical illness cover is unlikely to pay out if you’re diagnosed with coronavirus. That’s
          because viral infections like COVID-19 are not specified as critical illnesses, according
          to the Association of British Insurers. If you were to develop a secondary critical illness
          due to complications arising from coronavirus, your claim would be paid as long as it meets
          the terms laid out in your policy.
        </p>

        <p><strong>Is critical illness cover separate to life insurance?</strong></p>
        <p>
          Yes, critical illness cover is different to life insurance. Some life insurance providers may
          include critical illness cover as part of your life insurance policies, while others offer
          it as a policy add-on. If you’re only interested in critical illness cover, you can find
          this separately.
          </p>

        <p><strong>How is critical illness different to terminal illness cover?</strong></p>
        <p>
          Critical illness tends to pay out if a person suffers a serious ailment that isn’t life-threatening.
        </p>
        <p>
          Terminal illness is an incurable disease where a person is expected not to live longer than within
          12 months of their diagnosis. A policyholder is typically paid out a lump sum once their
          life-threatening condition, such as cancer, has been confirmed by a medical professional.
        </p>
        <p>
          It's worth being aware that most insurance providers will include terminal illness – which
          also could include illness or injury resulting in total and permanent disability (TPD) –
          as part of their life cover.
          </p>

        <p><strong>Are all critical illness policies the same?</strong></p>
        <p>
          No. What defines a critical illness varies from provider to provider. This is why it’s
          really important to know what is and isn’t covered by your policy. It’s likely that your
          policy will pay out different amounts depending on the type of condition you’re diagnosed with.
        </p>
        <p>
          The policy you’re offered will depend on lifestyle factors and other considerations, such as
          any pre-existing medical conditions you might have.
        </p>
        <p>
          It also depends on whether you have critical illness cover in addition to or combined with a
          life insurance policy. If you take out life insurance with separate critical illness cover,
          you’ll receive a pay-out if you get a critical illness and pass away during the time your
          policy runs for. With a combined policy, you’ll only receive one payment, either if you’re
          diagnosed with a critical illness or at death.
        </p>

        <p><strong>How long does a critical illness cover policy last?</strong></p>
        <p>
          Critical illness insurance runs for as long as the policy you decide to take out, known as a term life policy.
        </p>
        <p>
          You may decide to get a term life policy that will run for the same length of time as the term of your mortgage.
        </p>
        <p>
          Alternatively, you could calculate the length of cover based on how long your dependants will need
          financial support from you, should you become ill.
        </p>

        <p><strong>Are there policies that last for the whole of my life?</strong></p>
        <p>
          Yes. These are known as life assurance policies, or whole of life policies. Unlike term policies, these
          don’t expire. They tend to be more expensive than term insurance products. Learn more about how whole of
          life insurance could meet your needs.
        </p>

        <p><strong>Can I adjust the level of cover I have?</strong></p>
        <p>
          Most critical illness insurance providers will allow you to make changes to the level of cover in your
          policy. This could be as simple as extending the term, or adding a greater level of cover. You should
          always check if you can adjust your policy before taking out cover.
        </p>

        <p><strong>Can I get joint cover for my partner?</strong></p>
        <p>
          You can take out a joint life insurance policy, which includes critical illness cover as an add-on. However,
          it’s important to note that you will usually only be able to claim for critical illness once between you.
          If one of you makes a claim, you will no longer have critical illness cover for the other.
        </p>

        <p><strong>Can I get critical illness cover for my children?</strong></p>
        <p>
          You can get critical illness cover for children, but this is not usually a standalone product.
          Children’s critical illness cover is normally included in most critical illness policies as
          standard but you can pay an additional fee for an enhanced version in the policy of a parent
          or guardian. If your child’s condition requires you to claim on the policy, you must do this
          as the main policyholder.
        </p>

        <p><strong>Can I claim on my critical illness policy more than once?</strong></p>
        <p>
          You can only claim on your critical illness policy once. This will provide a lump sum payment that will
          help you with medical costs or improving your quality of life. Any other pay-out will come from your
          life insurance policy upon your death.
        </p>

        <p><strong>When should you get critical illness insurance?</strong></p>
        <p>
          When you decide to get cover is up to you, but here are some examples of times you may think about
          getting a policy for that extra security:
        </p>
        <ul>
          <li>
            Buying a home – taking out critical illness insurance alongside your mortgage will protect your home,
            should you fall critically ill during the duration of your mortgage. If your claim is approved,
            you’ll receive a tax-free, one-off payment that could potentially pay off your mortgage entirely
          </li>
          <li>
            You have dependants – if you get married, or have children, you may be responsible for the financial
            security of others. If you were to fall seriously ill or become disabled, critical illness cover can
            help support your family when they need it most
          </li>
          <li>
            A new job – while getting a new job is often good news, if your employer doesn’t have a benefits
            package that offers suitable sickness pay, you may want to take out your own insurance policy to
            keep your future secure
          </li>
        </ul>

        <p><strong>How can I make a claim on critical illness cover?</strong></p>
        <p>
          If you’re diagnosed with a critical illness, you’ll need to let your insurance provider know as soon as
          possible. You can usually make a claim by phone or online. Your provider will review your claim and may
          contact your GP or consultant if they need any further medical evidence to support the diagnosis.
        </p>
        <p>
          If your claim is successful, you’ll receive a single lump-sum payment. How long it takes to process
          the claim will vary between providers, but it could take up to eight weeks.
        </p>

        <p><strong>If I don’t make a claim do I get money back?</strong></p>
        <p>
          No, you won’t get any money back if you never make a claim on your critical illness policy.
        </p>

        <p><strong>Are there any restrictions on how I can spend the money paid out?</strong></p>
        <p>
          You can spend the money on whatever you like, but most people use the lump-sum payment to pay for
          private medical treatment, adapt their home or cover monthly bills if they can’t work.
        </p>

        <p><strong>How many critical illnesses can I claim for?</strong></p>
        <p>
          A critical illness policy will only pay out once. Insurance providers usually list all the critical
          conditions they cover on their website and will clearly outline how serious they have to be before
          you can claim. Some offer policies for more than 50 conditions. The illnesses listed can vary
          greatly between providers, so it’s really important to read your policy documents carefully to
          make sure you know what you are and aren’t covered for.
        </p>

        <p><strong>Can I get cover for pre-existing medical conditions?</strong></p>
        <p>
          Some insurance providers will offer critical illness cover if you have a pre-existing medical
          condition, but others won’t. A pre-existing condition is an illness, disease or injury that you’ve
          received medical treatment for before, or an ongoing condition like multiple sclerosis or
          Parkinson’s. Providers will look at you and your family’s medical history to work out if they’re
          willing to offer you cover.
        </p>
        <p>
          If they do, it may be more expensive and have more exclusions than a standard policy. If you have a
          pre-existing condition, you should always be honest about it or your provider might reject any
          claims you make.
        </p>

        <p><strong>How can I find the right critical illness cover for me?</strong></p>
        <p>
          Our cheapest quotes may not necessarily be best for you - it’s important to remember that each
          policy will have different conditions and exclusions. Therefore, you should always choose life
          insurance with a great deal of care. Get peace of mind today and start a quote.
        </p>

      </div>
    </div>
    <ActionSection
        title="Get the right cover!"
        button-text="Get covered"
        @onButtonClicked="$router.push('/')">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>Click now to get on the road to great cover. Your progress will be
            saved so you can come back at any time.</p>
        </div>
      </div>
    </ActionSection>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../../bus'
import Header from '../../Sections/Header.vue'
import Hero from '../../Sections/Hero.vue'
import Contact from '../../Sections/Contact.vue'
import Footer from '../../Sections/Footer.vue'
import ActionSection from "../../Sections/ActionSection";
import Providers from "../../Sections/Providers";
import Nav from "./Nav";

export default {
  name: 'FAQs',
  components: {
    Nav,
    Providers,
    ActionSection,
    Header,
    Hero,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
