<template>
  <div :class="`rental-cover call-to-action container-fluid pt-5 ${ noBackground ? '' : `background`}`">
    <div class="container">
      <div class="row">
        <div class="position-relative flex-column justify-content-between col-12 col-lg-7">
          <div class="card rounded border border-success text-center p-3 mb-5 pt-5 mt-5 pt-md-3 mt-md-0">
            <p class="mb-1 mt-5 mt-md-0 pt-3 pt-md-0">Rental Cover starts from</p>
            <h1 class="font-weight-bold">under £10 <br class="d-none d-lg-block"/>a month</h1>
            <p class="mb-1">Less than the amount it costs for a<br class="d-none d-md-block"/> takeaway or streaming services.</p>
            <div class="row mb-3 d-none d-md-flex">
              <div class="col d-flex flex-column justify-content-between">
                <img src="@/assets/chinese.svg" alt="Chinese food" class="img-fluid" />
                <div class="d-flex flex-column">
                  <span class="font-weight-bold">£10</span>
                  <span class="font-weight-bold">Chinese takeaway</span>
                </div>
              </div>
              <div class="col">
                <img src="@/assets/covered.svg" alt="Covered" class="img-fluid" />
              </div>
              <div class="col d-flex flex-column justify-content-between">
                <img src="@/assets/netflix.svg" alt="Netflix" class="img-fluid" />
                <div class="d-flex flex-column">
                  <span class="font-weight-bold">£10</span>
                  <span class="font-weight-bold">Netflix subscription</span>
                </div>
              </div>
            </div>
            <div class="d-block d-md-none text-center">
              <img src="@/assets/chinese.svg" alt="Chinese food" class="img-fluid w-50 my-3" />
              <div class="font-weight-bold">£10</div>
              <div class="font-weight-bold">Chinese takeaway</div>
              <img src="@/assets/netflix.svg" alt="Netflix" class="img-fluid w-50 my-3" />
              <div class="font-weight-bold">£10</div>
              <div class="font-weight-bold">Netflix subscription</div>
              <img src="@/assets/covered.svg" alt="Covered" class="img-fluid w-50 my-3" />
            </div>

            <p class="font-weight-bold">£10 = Peace of mind for you and your family with Rental Cover</p>
            <p class="mb-1 small">Income Protection: 1st life, £1000<br/>Monthly benefit, deferred for 4 weeks, policy duration is to age 67, premiums are guaranteed.</p>
          </div>
          <img src="@/assets/circle-piggy.svg" alt="Piggy bank" class="img-fluid position-absolute circle-piggy" />
        </div>
        <div class="col-12 col-lg-5">
          <Section title="Protect rent and other bills with our help">
            <p>
              It’s common for homeowners to protect their mortgage payments, with 80% of them having a policy in place. As a tenant, it’s just as important to look after the place you call home in case you lose your job or can’t work due to illness.
            </p>
            <p>
              Our friendly team will help you find competitive rental cover that meets your needs – plus, you could find that it’s more affordable than you think.
            </p>
            <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-5 mb-4 d-block d-md-none btn-block"
                    type="button"
                    name="button"
                    @click="$emit('click')">
              Request a quote
            </button>
            <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-5 mb-md-4 mb-0 d-none d-md-block"
                    type="button"
                    name="button"
                    @click="$emit('click')">
              Request a quote
            </button>
          </Section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "../components/Section.vue";

export default {
  components: {
    Section
  },
  props: {
    noBackground: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.rental-cover {

  .ctaButton {
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 2rem;
  }

  .card.rounded.border-success {
    border-radius: 10px !important;
    border-width: 2px !important;
  }

  .card.rounded.border-success {
    .row {
      .col {
        flex-basis: inherit;
        width: 40%;
      }

      .col:first-child, .col:last-child {
        width: 25%;
      }
    }
  }
  .circle-piggy {
    top:0;
    right: 0;
    width: 165px;
    translate: 10px -45px;
  }
  @media (max-width: 991px) {
    .circle-piggy {
      width: 160px;
    }
  }
  @media (max-width: 767px) {
    .circle-piggy {
      left:0;
      right:0;
      margin:0 auto;
      translate: 0 -35px;
    }
  }

}
</style>