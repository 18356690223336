<template>
  <ul class="nav nav-pills nav-fill pb-4">
    <li class="nav-item">
      <router-link to="/faqs/critical-illness" class="nav-link">
        Critical Illness
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/faqs/income-protection" class="nav-link">
        Income Protection
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/faqs/life-insurance" class="nav-link">
        Life Insurance
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Nav"
}
</script>

<style scoped>

</style>