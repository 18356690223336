<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="mother.png"
        title="Frequently asked questions">
      <img :src="`${require(`@/assets/heros/mobile/faqs.svg`)}`"
           class="img-fluid d-block d-md-none mx-auto"/>
      <p>
        Here are some frequently asked questions that we get asked that may help you get started with
        getting the right cover.
      </p>
      <p>
        If you have any questions that are not answered here, please feel free to contact us or request a quote and we will be happy to help.
      </p>
    </Hero>
    <div class="faqs container-fluid pt-0 pt-md-4 pb-5">
      <div class="container">
        <Nav/>
        <p><strong>What is Life Insurance and how does it work?</strong></p>
        <p>
          Life insurance helps to protect your loved ones financially in the event of your death.
          But what is life insurance in practice? Life Insurance is designed to pay out a cash
          sum if you die or are diagnosed with a terminal illness with a life expectancy of less
          than 12 months, during the length of the policy. It could be used to help pay the
          mortgage or help protect the family's lifestyle and everyday living expenses. Life insurance
          is sometimes referred to as life assurance or level term assurance.</p>
        <p>
          You choose the amount of cover you need and how long you need it for. You can pay your
          premiums monthly or annually and can also choose between a joint life or a single life
          policy. Your premiums are guaranteed to stay the same unless you alter your policy.</p>
        <p>
          If you’re wondering, ‘do I need life insurance?’ this will depend on your individual circumstances.
          For example, life insurance can provide peace of mind for people with children, partners, or
          spouses that depend on them financially, as well as people with a mortgage on the family house.</p>
        <p>
          Life insurance policies have no cash value unless a valid claim is made.
        </p>

        <p><strong>Can I cancel my policy if I no longer need it?</strong></p>
        <p>
          Yes, you can cancel your life insurance policy at any time. After applying for cover
          you'll receive a 30 day cancellation notice which you must send back if you decide not
          to continue with the policy.</p>
        <p>
          If you cancel within this 30 day period, we will return any premiums paid. But if you
          cancel after this period you won't get anything back.</p>

        <p><strong>How much does life insurance cost?</strong></p>
        <p>
          Life insurance costs vary according to how much cover you need, your age, and other factors
          such as your health history. Premiums start from just a couple of pounds per month, but to
          get a more accurate idea of how much your cover could cost you'll need to speak with one of
          our specialist insurance advisors.</p>
        <p>
          If you have a history of smoking, this can also increase your life insurance costs as the
          risk of death is greater. Lifestyle factors such as dangerous jobs and hazardous hobbies are
          also taken into consideration.</p>
        <p>
          If you decide to add Critical Illness Cover to your life insurance policy when you apply,
          this will be for an extra cost.</p>
        <p>
          Once your policy has been agreed and is in place, your premiums are guaranteed and won’t
          change unless you make any changes. This will help you plan ahead and budget for the cost
          of life cover.</p>

        <p><strong>How much Life Insurance do I need?</strong></p>
        <p>
          Every family has a different set-up, so consider your personal circumstances. If you’re a
          homeowner, you may want to take out life insurance to cover the length of a mortgage; for
          example, 25 years. If you have a family, could your loved ones afford things like childcare,
          educational costs and daily living expenses?</p>
        <p>
          You may want to think about your annual salary multiplied by the number of years your family
          will be financially dependent on you. Think about the amount of money your family would need
          to maintain their lifestyle and cover things like everyday living expenses.</p>
        <p>
          If you'd like more help, speak to one of our specialist insurance advisors.
        </p>

        <p><strong>Can I put my life insurance policy in Trust?</strong></p>
        <p>
          Yes, by writing life insurance in trust this is one of the best ways to protect your family
          future in the event of your death. A trust is a legal arrangement which allows the owner of
          a life policy (the settlor) to give their policy to a trusted group of people (the trustees),
          who look after it. At some time in the future, they pass it on to some people from a group
          that the settlor has decided (the beneficiaries).</p>
        <p>
          When a life policy is looked after in this way, it is said to be ‘in trust’. The life policy
          which is in trust, and any payment received from the life policy are called the trust fund.</p>
        <p>
          It should help to ensure that the money paid out from the life policy can be paid to the
          right people quickly, without the need for lengthy legal processes. When you die, your
          personal representatives will need to obtain probate so that they have the authority to
          deal with your estate.</p>
        <p>
          In England and Wales either a ‘grant of probate’ or ‘grant of letters of administration’ is
          issued to your personal representatives. This process takes time and if you die without
          having made a will it takes even longer. Since the trustees are the owners of a policy
          placed in trust, they do not have to go through this process in order to make a claim.</p>

        <p><strong>What if I need to make a change to my life insurance policy?</strong></p>
        <p>
          You can make changes to your life insurance policy, please call us so we can consider your request.
        </p>
        <p>
          In terms of changing your life insurance policy, we recognise that sometimes your protection
          needs change so you can request any of the following changes to the policy:</p>
        <ul>
          <li>extend or reduce the period of cover</li>
        <li>increase or decrease the amount of cover</li>
        <li>remove a life from a joint policy</li>
        </ul>
        <p>
          Insurers also offer 'joint life policy separation'. This allows a joint policy to be split
          into two new single policies if a couple divorce, dissolve their registered civil partnership
          or change a joint mortgage into one name or take out a new mortgage in one name.</p>
        <p>
          The changes could affect the premiums that you pay and the insurer would have to assess
          any change requested to your life insurance based on your circumstances at the time.</p>

        <p><strong>What's the difference between Life Insurance vs Over 50s Fixed Life Insurance?</strong></p>
        <p>
          If you’re looking at your options later in life, you may be torn between getting life insurance
          or Over 50s Fixed Life Insurance. Let’s take a look at some of the differences.</p>
        <p>
          Life insurance is designed to pay out a cash sum if you die during the length of your policy. When
          assessing your life insurance application, we'll ask you questions about your medical history,
          occupation, and lifestyle. Life insurance is there to offer financial protection to your loved
          ones. The cash sum paid out could be used by them to pay off a mortgage for example, or help
          with childcare costs and other everyday living expenses. Some age restrictions apply.</p>
        <p>
        Over 50s Fixed Life Insurance is designed for UK residents aged 50 to 80 who want a fixed cash
          sum to give to their loved ones when they die. It's for people who want guaranteed acceptance
          with a simple application process and who don't want to answer medical or lifestyle questions.
          Full cover is payable after just one year.</p>
        <p>
          There are other important factors that you need to be aware of when deciding on life insurance
          vs Over 50s Fixed Life Insurance so make sure you speak with one of our specialist insurance
          advisors before you decide what you want to do.</p>
        <p>
          Please remember that neither of these plans are a savings or investment product and have no
          cash value unless a valid claim is made.</p>

        <p><strong>What's the difference between Terminal Illness Cover and Critical Illness Cover?</strong></p>
        <p>
          Terminal Illness Cover is included on our life insurance policies at no extra cost (minimum
          term of two years). It could pay out your chosen amount of cover if you're diagnosed with a
          terminal illness and have a life expectancy of 12 months or less, rather than on death. It
          can help provide financial support at a time when it could be needed most.</p>
        <p>
          Critical Illness Cover can be added when you take out Life Insurance or Decreasing Life Insurance
          for an additional cost. It's designed to pay out your chosen amount of cover if you're diagnosed
          with or undergo a medical procedure for one of our specified critical illnesses during the
          length of your policy and you survive for 14 days from diagnosis.</p>
        <p>
          To find out more about the difference between terminal illness and critical illness cover
          speak to one of our specialist insurance advisors. Please remember that life insurance is not a
          savings or investment product and has no cash value unless a valid claim is made.</p>

        <p><strong>Can I add Critical Illness Cover?</strong></p>
        <p>
          Yes, you can add Critical Illness Cover at an additional cost when you take out Life Insurance
          or Decreasing Life Insurance.</p>
        <p>
          It could pay out a cash sum if you’re diagnosed with or undergo a medical procedure for one of
          the specified critical illness that is included in your cover during the length of your policy,
          and you survive for 14 days from diagnosis.</p>
        <p>
          Critical illnesses cover include many types of cancer, heart attack and stroke. You just need
          to remember that some types of cancers are not included, and you need to have permanent
          symptoms to make a claim for some illnesses.</p>
        <p>
          Please remember that these policies are not savings or investment products and have no cash
          value unless a valid claim is made.</p>

        <p><strong>What does life insurance not cover?</strong></p>
        <p>
          You won’t be covered if you die within the first year of your policy as a result of suicide,
          death caused by intentional and serious self-injury, or an event where in our reasonable
          opinion, the insured person took their own life.</p>
        <p>
          We won't pay out if you are diagnosed with a terminal illness which doesn't meet the insurers definition.</p>
        <p>
          There could be some circumstances where the insurers are not able to provide full cover based
          on the answers you gave in your application. If there are any exclusions to your cover, these
          will be explained in your Policy Booklet.</p>
        <p>
          Your cover will end if you stop paying your premiums when due and it's important to remember
          that life insurance is not a savings or investment product and has no cash value unless a valid claim is made.</p>

        <p><strong>Do I legally need life insurance for a mortgage?</strong></p>
        <p>
          There is no legal requirement for you to take out life insurance when you get a mortgage, however
          it can make sense to have financial protection in place. If you are a homeowner, a mortgage is
          likely to be the biggest debt you leave behind should the worst happen, so having a policy in
          place can help give you peace of mind.</p>
        <p>
          Life insurance can help protect the family home by paying out a cash sum if you die during
          the length of the policy. This could be used by your family to help clear the outstanding
          mortgage debt. Your loved ones could then continue living in your family home without worrying
          about the mortgage.</p>
        <p>
          Please remember that life insurance is not a savings or investment product and has no cash
          value unless a valid claim is made.</p>

        <p><strong>Can you get life insurance if you have a pre-existing medical condition?</strong></p>
        <p>
          Yes, you can get life insurance with a pre-existing medical condition, depending on how
          severe your health problem is, and the outcome of your medical assessment.</p>
        <p>
          You’ll need to complete a life insurance application and answer the medical questions
          you’re asked, which will help the insurer decide your premiums and whether you’re
          entitled to life insurance. Examples of pre-existing medical conditions which you’ll
          need to disclose include diabetes, heart conditions and kidney illnesses.</p>
        <p>
          You would also need to declare a pre-existing medical event, such as a heart attack.
        </p>

        <p><strong>How long should I get life insurance for?</strong></p>
        <p>
          You should think carefully about how long you need cover for. Some people decide to get
          life insurance to cover the length of a mortgage term; say, 25 years, while others think
          about how long their children will be financially dependent on them.</p>
        <p>
          Ultimately, it’s a personal decision and you should factor in your family’s unique circumstances.
        </p>

        <p><strong>Can I have multiple life insurance policies at the same time?</strong></p>
        <p>
          Yes, there is nothing to stop you having more than one life insurance policy. In fact,
          you can have as many policies as you want. However, if you find your situation changes
          in the future it's sometimes possible to adapt your existing policy. For example, if we
          arranged your life insurance policy you may be able to increase your cover without the
          need for further medical information on certain life events. You can also request to
          make other changes, such as changing the term, the amount of cover, removing a name
          from a joint policy or even changing your premium payments from monthly to annually.
        </p>

      </div>
    </div>
    <ActionSection
        title="Get the right cover!"
        button-text="Get covered"
        @onButtonClicked="$router.push('/')">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>Click now to get on the road to great cover. Your progress will be
            saved so you can come back at any time.</p>
        </div>
      </div>
    </ActionSection>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../../bus'
import Header from '../../Sections/Header.vue'
import Hero from '../../Sections/Hero.vue'
import Contact from '../../Sections/Contact.vue'
import Footer from '../../Sections/Footer.vue'
import ActionSection from "../../Sections/ActionSection";
import Providers from "../../Sections/Providers";
import Nav from "./Nav";

export default {
  name: 'FAQs',
  components: {
    Nav,
    Providers,
    ActionSection,
    Header,
    Hero,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
