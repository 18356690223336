<template>
  <div>
    <nav ref="header" class="navbar p-0 m-0 fixed-top navbar-expand-md navbar-light bg-white flex-column">
      <div class="container px-1 py-2">
        <router-link class="navbar-brand" to="/">
          <img @click="headerLogoClicked" src="@/assets/logo-believe-protect.svg" class="logo" :alt="branding.shortName">
        </router-link>
        <button class="navbar-toggler" type="button"
                @click="isCollapsed = !isCollapsed"
                data-toggle="collapse"
                data-target="#navBarProtect"
                aria-controls="navBarProtect"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" v-bind:class="{ collapse: isCollapsed }" id="navBarProtect">
          <div class="
            navbar-nav
            d-flex flex-fill flex-column
            flex-sm-row
            justify-content-end
            align-items-center
            pt-3
            pt-md-0
          ">
            <div class="
            d-md-none
            links
            d-flex align-items-cneter align-items-sm-start align-items-md-center justify-content-around
            flex-grow-1 flex-column flex-lg-row
            ">
              <router-link
                  class="pt-1 pb-1 pb-md-0"
                  v-for="(link) in links"
                  :key="link.slug"
                  :to="`/${link.slug}`"
              >{{ link.text }}
              </router-link>
            </div>
            <div class="call m-0 p-0 mr-sm-3 mr-md-0">
              <h3 class="navText text-center">Call <span>from a mobile or Landline:</span></h3>
              <p class="navNumber text-center text-sm-right"><a class="rulertel" :href="`tel:${branding.phone.replaceAll(' ', '')}`">{{ branding.phone }}</a></p>
            </div>
            <a :href="`https://uk.trustpilot.com/review/${branding.website.shortUrl}`" target="_blank"
               class="ml-xl-5 d-none d-xl-block">
              <img style="float:right" src="@/assets/header-trustpilot.svg" width="110" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="container-fluid desktop-buttons navbar d-none d-md-block">
        <div class="container d-flex">
          <router-link
              class="pt-1 pb-1 pb-md-0"
              v-for="(link) in links"
              :key="link.slug"
              :to="`/${link.slug}`"
          >{{ link.text }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Bus } from "../bus";
import {branding} from "@/branding";

export default {
  mounted() {
    // on resize, emit the new height
    window.addEventListener('resize', () => {
      Bus.$emit('headerHeight', this.$refs.header.clientHeight);
    });
    setTimeout(() => {
      Bus.$emit('headerHeight', this.$refs.header.clientHeight);
    }, 1);
  },
  data() {
    return {
      isCollapsed: true,
      branding: branding,
      links: [
        {
          slug: 'critical-illness',
          text: 'Critical Illness'
        },
        {
          slug: 'income-protection',
          text: 'Income Protection'
        },
        {
          slug: 'life-insurance',
          text: 'Life Insurance'
        },
        {
          slug: 'rental-cover',
          text: 'Rental Cover'
        },
      ]
    }
  },
  methods: {
    headerLogoClicked() {
      Bus.$emit('headerLogoClicked');
    }
  }
}
</script>

<style scoped>

.desktop-buttons {
  background: #56B27F;
}

.desktop-buttons a {
  font-weight: bold;
  color:white;
}

.logo {
  width: 200px;
}
.links a {
  color: #56B27F !important;
  font-size: 0.875em;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}
.links a:hover, .links a:focus, .links a:active {
  text-decoration: underline;
}

.navText,.navNumber {
  font-family: Montserrat;
  text-align: right;
  line-height: 24px;
}

.navText {
  color: #0e2428;
  font-size: 0.875em;
  margin: 0.75em 0 0.25em;
}

.navNumber {
  font-weight: 600;
  color: #56B27F !important;
  font-size: 1.1em;
}

.navNumber a {
  color: inherit;
}

@media (max-width: 768px){
  .navText {
    font-size: 0.8em;
    width: 100%;
  }
  .logo {
    width: 150px;
  }
}

@media (max-width: 430px){
  .navText {
    margin-top:10px;
  }
}

@media (max-width: 390px){
  .logo {
    width:120px;
  }
}

@media (max-width: 350px){
  .navNumber {
    margin: 0px 0px;
  }
}

</style>