<template>
  <div :class="`how-it-works pt-4 pb-4 ${noBackground ? '' : 'background'}`">
    <div class="container-fluid">
      <div class="container text-center">
        <h2 class="title">How it works</h2>
        <div class="row">
          <div class="
          col-12 offset-0 px-0 pb-3
          col-md-6 offset-md-3
          col-lg-4 offset-lg-0 px-lg-5
          ">
            <img src="@/assets/how-it-works/step-1.svg" class="img-fluid col-6 col-md-12 pb-4"/>
            <h3>Step 1.</h3>
            <p>Simple, easy application</p>
          </div>
          <div class="
          col-12 offset-0 px-0 pb-3
          col-md-6 offset-md-3
          col-lg-4 offset-lg-0 px-lg-5
          ">
            <img src="@/assets/how-it-works/step-2.svg" class="img-fluid col-6 col-md-12 pb-4"/>
            <h3>Step 2.</h3>
            <p>We search a panel of the UK’s leading insurance providers to find the right policy for you</p>
          </div>
          <div class="
          col-12 offset-0 px-0 pb-3
          col-md-6 offset-md-3
          col-lg-4 offset-lg-0 px-lg-5
          ">
            <img src="@/assets/how-it-works/step-3.svg" class="img-fluid col-6 col-md-12 pb-4"/>
            <h3>Step 3.</h3>
            <p>Our bespoke technology and skilled advisors support you all the way</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 order-sm-1 text-sm-left">
            <button class="btn btn-xl mt-3 mb-3 more"
                    type="button"
                    name="button"
                    @click="() => { poweredBy = !poweredBy }"
            >
              Find out more {{ poweredBy ? "&#9650;" : "&#9660;"}}
            </button>
          </div>
          <div class="col-12 col-sm-6 order-sm-0 text-sm-right">
            <button class="btn btn-xl btn-secondary mt-3 mb-3"
                    type="button"
                    name="button"
                    @click="scrollTop"
            >Let's get started</button>
        </div>
        </div>
        <div :class="`row pt-5 click-tech  ${poweredBy ? `` : `d-none`}`">
          <div class="col-12 col-md-6 order-md-1 text-left">
            <h3 class="mb-4">Believe is powered by Clicktech</h3>
            <p>We want you to get your quote quickly and start your protection journey as
              soon as you’re ready. So, we’ve developed a powerful technology platform,
              called Clicktech, that makes the process fast and efficient with you in control.
            </p>
            <p>Following a chat with one of our friendly advisors, we link you to our simple
              online portal where you can enter the brief information we need. We then search
              our panel of the UK’s leading insurers and deliver the most competitive quotes
              quickly and easily, in moments.
            </p>
            <p>And once you’ve made your choice, Clicktech keeps things moving, helping you get protected in no time.</p>
          </div>
          <div class="col-12 col-md-6 order-md-0">
            <img src="@/assets/how-it-works/clicktech.svg" class="img-fluid p-5" alt="Click Tech"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "HowItWorks",
      data() {
        return {
          poweredBy: false
        }
      },
      props: {
        noBackground: {
          type: Boolean,
          default: false
        }
      },
      methods: {
        scrollTop() {
          window.scrollTo(0, 0);
        }
      }
    }
</script>

<style lang="scss" scoped>

.how-it-works {
  color: #17174C;
  &.background {
    background-color: rgba(107,179,198,0.2);
  }
  h3 {
    font-size:1.25rem;
  }
  p {
    font-size:1rem;
  }
  button {
    font-size: 1.2rem;
    border-radius: 2rem;
    padding: 1rem 2rem;
  }
  button.more {
    color:white;
    background-color: #17174C;
  }
  .click-tech {
    animation: ease-in-out;
    transition: display;
  }
}

</style>
