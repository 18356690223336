<template>
    <div :class="`call-to-action container-fluid pt-5 ${ !noBackground ? (swap ? `background` : ``) : ''}`">
      <div class="container">
          <div class="row">
            <div :class="`order-0
            col-12 offset-0
            col-md-6
            ${swap ? `order-md-1 offset-lg-1` : ``}`">
              <Section
                :title="title"
                :leading="leading"
                :points="points"
                bullet="circle"
                >
                <slot></slot>
              </Section>
            </div>
            <div :class="`flex-column justify-content-between order-1
            col-10 offset-1
            col-sm-8 offset-sm-2
            col-md-6 offset-md-0
            col-lg-5 offset-lg-0
            ${swap ? `order-md-0` : `offset-lg-1`}`">
              <slot name="aside">
              <img :src="`${require(`@/assets/sections/${image}.svg`)}`"
                   :alt="image"
                   :class="`img-fluid flex-fill mb-4 ${counter ? `pb-5` : ``}`">
              <LendingCounter class="lending-counter" v-if="counter"  />
              </slot>
            </div>
          </div>
          <slot name="extra"></slot>
      </div>
    </div>
</template>

<script>
import LendingCounter from "../components/LendingCounter";
import Section from "../components/Section";

export default {
  name: "CallToAction",
  components: {
    Section,
    LendingCounter,
  },
  props: {
    title: {
      type:String,
      required: true
    },
    leading: {
      type:String,
      required: false
    },
    image: {
      default: "car",
      type: String
    },
    points: {
      default: () => [],
      type: Array
    },
    swap: {
      default: false,
      type: Boolean
    },
    noBackground: {
      default: false,
      type: Boolean
    },
    counter: {
      default: false,
      type: Boolean
    },
  },
  methods: {
      scrollTop() {
          window.scrollTo(0, 0);
      },
      onButtonClicked(form) {
        this.$emit("on-button-clicked", form);
      }
  }
}
</script>

<style lang="scss">

.call-to-action {
  color:#17174C;
}

.call-to-action.background {
  background-color: rgba(107,179,198,0.2);
}

.call-to-action .leading {
  font-size:17px;
  line-height:35px;
  font-weight: 600;
}

.call-to-action .lending-counter {
  position: absolute;
  bottom: 0;
  left:0;
  z-index: 100;
  float: left;
  margin-left:0;
}

</style>