<template>
  <div class="protect-your-family pt-4 pb-4">
    <div class="container-fluid">
      <div class="container text-center">
        <h2 class="title">Protect your family with our help</h2>
        <div class="row">
          <div class="
          col-12 px-0 pb-3
          col-md-8 offset-md-2
          col-lg-4 offset-lg-0 px-lg-5
          d-flex flex-column justify-content-between">
            <div>
              <img src="@/assets/protect-your-family/step-1.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
              <h3><router-link to="life-insurance">Life Insurance</router-link></h3>
              <p>If you were to die, how would your loved one cope financially?</p>
              <p>Life insurance takes away the worry, paying out a tax-free lump sum in the event of your death.</p>
            </div>
            <button :class="`btn btn-secondary ctaButton px-5 py-2 py-lg-3`"
                    type="button"
                    name="button"
                    @click="onButtonClicked('lifeInsurance')">
            Request a quote</button>
          </div>
          <div class="
          col-12 px-0 pb-3
          col-md-8 offset-md-2
          col-lg-4 offset-lg-0 px-lg-5
          d-flex flex-column justify-content-between">
            <div>
              <img src="@/assets/protect-your-family/step-2.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
              <h3><router-link to="income-protection">Income Protection</router-link></h3>
              <p>How would you pay your bills if you were unable to work due to illness or injury?</p>
              <p>Income protection provides you with a monthly benefit until you are able to return to work.</p>
            </div>
            <button :class="`btn btn-secondary ctaButton px-5 py-2 py-lg-3`"
                    type="button"
                    name="button"
                    @click="onButtonClicked('incomeProtection')">
              Request a quote</button>
          </div>
          <div class="
          col-12 px-0 pb-3
          col-md-8 offset-md-2
          col-lg-4 offset-lg-0 px-lg-5
          d-flex flex-column justify-content-between">
            <div>
              <img src="@/assets/protect-your-family/step-3.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
              <h3><router-link to="critical-illness">Critical Illness</router-link></h3>
              <p>If you were to fall critically ill how would you and your family cope financially?</p>
              <p>With critical illness cover you get a tax-free lump sum to help you adjust.</p>
            </div>
            <button :class="`btn btn-secondary ctaButton px-5 py-2 py-lg-3`"
                    type="button"
                    name="button"
                    @click="onButtonClicked('criticalIllness')">
              Request a quote</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "ProtectYourFamily",
      methods: {
        scrollTop() {
          window.scrollTo(0, 0);
        },
        onButtonClicked(form) {
          this.$emit('on-button-clicked', form);
        }
      }
    }
</script>

<style lang="scss" scoped>
.protect-your-family {
  color: #17174C;
  h3 {
    font-size:1.25rem;
  }
  p {
    font-size:1rem;
  }
  .ctaButton {
    font-size:1rem;
    font-weight: bold;
    border-radius: 2rem;
  }
}
</style>
