import Main from './Views/Home'
import LifeInsurance from "./Views/LifeInsurance";
import IncomeProtection from "./Views/IncomeProtection";
import CriticalIllness from "./Views/CriticalIllness";
import PrivacyPolicy from './Views/Policy/PrivacyPolicy'

import FAQCriticalIllness from "./Views/FAQs/CriticalIllness";
import FAQIncomeProtection from "./Views/FAQs/IncomeProtection";
import FAQLifeInsurance from "./Views/FAQs/LifeInsurance";

import AZLifeInsurance from "./Views/AZLifeInsurance";

import ThankYou from "./Views/ThankYou";
import TermsAndConditions from "./Views/Policy/TermsAndConditions";
import CookiePolicy from "./Views/Policy/CookiePolicy";
import ComplaintPolicy from './Views/Policy/ComplaintsPolicy';
import RentalCover from "./Views/RentalCover.vue";

const root = "";

export const routes = [
    {
        name: "Home",
        path: root,
        component: Main
    },
    {
        name: "Cashback",
        path: `${root}/cashback`,
        component: Main
    },
    {
        name: "LifeInsurance",
        path:`${root}/life-insurance`,
        component: LifeInsurance
    },
    {
        name: "IncomeProtection",
        path:`${root}/income-protection`,
        component: IncomeProtection
    },
    {
        name: "RentalCover",
        path:`${root}/rental-cover`,
        component: RentalCover
    },
    {
        name: "CriticalIllness",
        path:`${root}/critical-illness`,
        component: CriticalIllness
    },
    {
        name: "FAQCriticalIllness",
        path:`${root}/faqs/critical-illness`,
        component: FAQCriticalIllness
    },
    {
        name: "FAQIncomeProtection",
        path:`${root}/faqs/income-protection`,
        component: FAQIncomeProtection
    },
    {
        name: "FAQLifeInsurance",
        path:`${root}/faqs/life-insurance`,
        component: FAQLifeInsurance
    },
    {
        name: "AZLifeInsurance",
        path:`${root}/life-insurance-a-to-z`,
        component: AZLifeInsurance
    },
    {
        path:`${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Thank You",
        path: `${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Cookies",
        path: `${root}/cookies`,
        component: CookiePolicy
    },
    {
        name: "Privacy",
        path: `${root}/privacy`,
        component: PrivacyPolicy
    },
    {
        name: "Terms",
        path: `${root}/terms`,
        component: TermsAndConditions
    },
    {
        name: "Complaints",
        path: `${root}/complaints`,
        component: ComplaintPolicy
    }
];
