<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="life-insurance.png"
        title="It’s so simple to get your life insurance sorted.">
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 mb-4 d-block d-md-none btn-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('lifeInsurance')">
        Request a quote
      </button>
      <p>
        None of us know what’s really round the corner. At any time in your
        life the unexpected can happen, and you want to know that your family
        and loved ones will be financially secure after you’re gone.
      </p>
      <p>
        With the correct life insurance in place, you, and your family will
        have the peace of mind that if the time comes, your financial worries
        will have already been taken care of.
      </p>
      <p>We make it so simple to sort out this valuable protection. We find the
        most competitive quotes quickly and easily, and our friendly team of
        expert advisors supports you all the way.
      </p>
      <button class="btn btn-xl btn-secondary ctaButton px-5 py-2 py-lg-3 mt-2 d-none d-md-block"
              type="button"
              name="button"
              @click="$refs.hero.showAppForm('lifeInsurance')">
      Request a quote
      </button>
    </Hero>
    <TrustPilot />
    <InfoSection
        image="family-children"
        title="Things to know about life insurance"
        :swap="true"
    >
      <p>If you die, a life insurance policy will pay out a tax-free lump sum to your family to give
        them financial security after you’ve gone. If there are people who depend on the money you
        earn, life insurance can protect their financial security when you’re no longer around.</p>
      <p>The cost of life insurance depends on a number of different things, the amount of cover you
        want, your age, smoker status and your medical history.</p>
      <p>There are a few different types of life insurance, don’t worry about which you need, our
        team of expert advisors will recommend the right type of cover for you and your family.</p>
      <p>To make sure your life insurance pays out you need to be honest and upfront about any
        past or pre-existing health conditions.</p>
      <p>Claiming on life insurance is simple, your partner or family need to
        contact the insurance company, so you need to make sure your documents are kept in a safe place.</p>
    </InfoSection>
    <InfoSection
        image="moving"
        title="What can a life insurance lump sum be used for?"
    >
      <p>Life insurance can be used for a number of different things, it could pay off your mortgage
        or other everyday debts, if you rent it could be used to cover the cost of your rent for a
        period of time. The everyday costs of living won’t go away if you’re no longer around so life
        insurance can help your family cover these costs, and finally, the last thing your family
        wants to think about when you die is finding the money to pay for your funeral, with life
        insurance in place they won’t need to worry about that.</p>
    </InfoSection>
    <InfoSection
        image="family-sec"
        title="Who could benefit from life insurance?"
        :swap="true"
        :no-background="true"
    >
      <p>Life insurance is normally set up to help your family with the financial burden of death,
        so if you’re a couple or married with shared debts like a mortgage, life insurance can be
        used to pay these debts off. If you’re a parent life insurance can help provide for your
        children when you’re no longer there.</p>
    </InfoSection>
    <ActionSection
        title="How much cover should I have?"
        button-text="Request a quote"
        @onButtonClicked="scrollTop">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>The amount of cover we arrange is important, and we’ll help make sure it’s right. Our
            expert advisors will help you consider everything that’s important, and we’ll work
            together to recommend a bespoke plan to suit your needs.</p>
        </div>
      </div>
    </ActionSection>
    <FAQs/>
    <HowItWorks/>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'
import InfoSection from "../Sections/InfoSection";
import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import Contact from '../Sections/Contact.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";
import ActionSection from "../Sections/ActionSection";
import Providers from "../Sections/Providers";
import FAQs from "../Sections/FAQs";

export default {
  name: 'LifeInsurance',
  components: {
    FAQs,
    Providers,
    ActionSection,
    InfoSection,
    HowItWorks,
    Header,
    Hero,
    TrustPilot,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
