<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        ref="hero"
        image="family-alt.png"
        title="Frequently asked questions">
      <img :src="`${require(`@/assets/heros/mobile/faqs.svg`)}`"
           class="img-fluid d-block d-md-none mx-auto"/>
      <p>
        Here are some frequently asked questions that we get asked that may help you get started with
        getting the right cover.
      </p>
      <p>
        If you have any questions that are not answered here, please feel free to contact us or request a quote and we will be happy to help.
      </p>
    </Hero>
    <div class="faqs container-fluid pt-0 pt-md-4 pb-5">
      <div class="container">

        <Nav/>

        <p><strong>Who is Income Protection Benefit for?</strong></p>
        <p>
          The cover is designed for those who want comprehensive cover with a choice of options to
          help protect against a loss of income, if unable to work due to incapacity caused by
          illness or injury, while covered by the plan.
        </p>

        <p><strong>Can I get cover if I’m unemployed?</strong></p>
        <p>
          You can’t be unemployed when taking out the policy. You need to work 16 or more hours per week.
        </p>
        <p>
          Income Protection Benefit is not unemployment cover, so it won’t pay out due to being made
          unemployed. If you become unemployed whilst you have the policy you can still claim, if you
          become ill or injured, so long as you’ve continued to pay for the policy after becoming
          unemployed.
        </p>

        <p><strong>How much could I receive?</strong></p>
        <p>
          Your income is covered if you have an accident or become ill and need to take continuous time
          off work for 4 weeks or more. The maximum payout you can receive will by based on up to 65%
          of your gross income.
        </p>

        <p><strong>How do I make a claim?</strong></p>
        <p>
          Please call your insurer as soon as you know you’re going to be off work for 4 weeks or
          more and depending on the waiting period chosen no later than 2 weeks of being off work
          so that the waiting period is not deemed to have started later. Your insurer will discuss
          your illness or injury, send you a claim form so you can fill out your medical
          information and give them details about your illness or injury. They will also request
          your financial information and details relating to your work. Your claim will be assessed,
          and monthly payout will start if the claim is valid.
        </p>

      </div>
    </div>
    <ActionSection
        title="Get the right cover!"
        button-text="Get covered"
        @onButtonClicked="$router.push('/')">
      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2">
          <p>Click now to get on the road to great cover. Your progress will be
            saved so you can come back at any time.</p>
        </div>
      </div>
    </ActionSection>
    <Contact />
    <Providers />
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../../bus'
import Header from '../../Sections/Header.vue'
import Hero from '../../Sections/Hero.vue'
import Contact from '../../Sections/Contact.vue'
import Footer from '../../Sections/Footer.vue'
import ActionSection from "../../Sections/ActionSection";
import Providers from "../../Sections/Providers";
import Nav from "./Nav";

export default {
  name: 'FAQs',
  components: {
    Nav,
    Providers,
    ActionSection,
    Header,
    Hero,
    Contact,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style  lang="scss" scoped>
.ctaButton {
  font-size:1.25rem;
  font-weight: bold;
  border-radius: 2rem;
}
</style>
