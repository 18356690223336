<template>
  <div class="section pb-5" :class="parentClass">
      <h1 class="title">{{ title }}</h1>
      <slot name="fold"></slot>
      <h5 v-if="leading" class="mt-3">{{ leading }}</h5>
      <div v-if="points.length">
        <div
            v-for="(item) in points"
            :key="item"
            :class="`d-flex justify-items-center align-items-center copy mt-3 bullet-${bullet}`"
        >
          <img :src="`${require(`@/assets/icons/${bullet}.svg`)}`"
               alt="Bullet Point"
               :class="`mr-3 img-fluid ${bullet}`">
          {{ item }}
        </div>
      </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    parentClass: {
      type:String
    },
    title: {
      type:String,
      required: true
    },
    leading: {
      type:String,
      required: false
    },
    image: {
      default: "car",
      type: String
    },
    bullet: {
      default: "tick",
      type: String
    },
    points: {
      default: () => [],
      type: Array
    }
  },
}
</script>

<style scoped>

/* ------------------------------------------------------------------------------- */
/* Section */
/* ------------------------------------------------------------------------------- */
.section h5 {
  color:white;
  font-size: 1.5rem;
  line-height: 2rem;
}

.section img.tick {
  width: 40px;
  min-width:40px;
}

.section p {
  color:#17174C;
  font-size:17px;
  line-height:28px;
}

.section div.copy {
  font-size:22px;
  line-height: 34px;
  color:#17174C;
}

.section div.bullet-circle {
  font-size: 18px;
}

@media (max-width: 991px){
  .section p {
    font-size:15px;
    line-height:25px;
  }
  .section div.bullet-tick {
    font-size:18px;
    line-height: 25px;
    font-weight: 600;
    color:#17174C;
  }
  .section img.tick {
    width: 34px;
    min-width: 34px;
  }
}

@media (max-width: 767px){
  .section div.copy {
    font-size:16px;
    line-height: 22px;
  }
}

@media (max-width: 767px){
  .section div.copy {
    font-size:16px;
    line-height: 20px;
  }
  .section div.copy img {
    width:20px;
    min-width:20px;
  }
}

/* ------------------------------------------------------------------------------- */
/* Hero (Title Width */
/* ------------------------------------------------------------------------------- */

.hero.section .title {
  display: flex;
  align-items: center;
  width:70%;
  min-height:100px;
}

@media (max-width: 767px){
  .hero.section .title {
    font-size:22px;
    line-height: 40px;
  }
}

@media (max-width: 575px){
  .hero.section .title {
    width:58%;
    font-size:22px;
    line-height: 35px;
  }
}

@media (max-width: 400px){
  .hero.section .title {
    width:58%;
    font-size:20px;
    line-height: 25px;
  }
}

</style>