import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import tracking from "./tracking";
import {routes} from './routes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClipboardList, faSearch, faLock, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ObserveVisibility } from 'vue-observe-visibility'
import anime from 'animejs/lib/anime.min.js';
import './app.css'

library.add(faClipboardList, faSearch, faLock, faHandshake, faCheckCircle); 
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.directive('observe-visibility', ObserveVisibility)
Vue.prototype.$anime = anime;

window.ClickAppTrack = tracking();

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) {
      if (to.hash === '#anchor__why') {
        return { selector: to.hash, offset: { x:0, y: 88 } }; // header offset
      }
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes,
  linkActiveClass: "active"
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
